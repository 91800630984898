// Media query breakpoints
$break_xs: 576px;
$break_s: 768px;
$break_l: 992px;
$break_xl: 1200px;
$break_xxl: 1500px;
$break_xxxl: 1800px;

// Feature colors
// $featureColor1: #cddb00;
$featureColor1: #c1d72d;
// $featureColor2: #a4b92d;
$featureColor2: #b0c630;
// $featureColor3:  #ffb600;
$featureColor3: #fcb316;
// $featureColor4: #00c1de;
$featureColor4: #1ac2fb;
$textDark: #596268;
$textLight: #7b868c;
$heroText: #36434c;
$backgroundLight: #f5f4f0;
$backgroundDark: #4b4b57;

//Font imports
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,800&display=swap'); //Navigation
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700'); // Text and Headings
@import url('https://fonts.googleapis.com/css?family=Dancing+Script:400,700&display=swap');
@font-face {
    font-family: "Tahu";
    src: url('/static/fonts/Tahu!.ttf') format('truetype')
}

//Global styles
* {
    box-sizing: border-box;
    position: relative;
}

a {
    color:$featureColor4;
    &:hover {
        color: $featureColor4;
    }
}

.c-featureColor1 {
    color: $featureColor1;
}
.c-featureColor2 {
    color: $featureColor2;
}
.c-featureColor3 {
    color: $featureColor3;
}
.c-featureColor4 {
    color: $featureColor4;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

body,
html {
    height: 100%;
    width: 100%;
    margin: 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

html {
    overflow-y: scroll;
}

.content-hover-name {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 15px 20px !important;
    background-color: white !important;
    box-shadow: 0px 1px 10px #b0c630 !important;
    color: #b0c630 !important;
    font-weight: bold !important;
    border-radius: 10px !important;
    font-size: 1rem !important;
    text-align: center !important;
    white-space: pre !important;
    z-index: 999999999999;
}

#root {
    min-height: 100vh;
    background-color: $backgroundLight;
    // padding-bottom: 692px;
    overflow-x: hidden;
    // @media (min-width: $break_xs) {
    //     padding-bottom: 566px;
    // }
    // @media (min-width: $break_s) {
    //     padding-bottom: 400px;
    // }
    @media print {
        min-height: 0;
        padding-bottom: 0;
        background-color: white;
    }
    .video-wrapper {
        border-radius: 3px;
        font-size: 0;
        overflow: hidden;
        box-shadow: 0px 3px 13px #e0e0e0;
        iframe {
            width: 100%;
            height: 300px;
        }
    }
    .field {
        margin-bottom: 15px;
        text-align: left;
        input,
        textarea {
            resize: vertical;
        }
        &:not(.checkboxField) > label {
            padding: 10px 0px 20px 10px;
        }
        label {
            color: $featureColor2;
            margin-left: 0px;
            font-size: 1.2rem;
        }
        textarea {
            min-height: 130px;
        }
        &.paymentField {
            margin: 20px 0px;
        }
        &.paymentField {
            & > .inner {
                padding: 12px 120px 0 0;
            }
            .StripeElement {
                padding: 13px 20px;
                border: 2px solid grey;
                box-shadow: 0px 2px 3px #00000029;
                display: block;
                width: 100%;
                border-radius: 30px;
                /* padding: 10px 15px; */
                background-color: white;
                /* border: 0; */
                outline: 0;
                height: 47px;
                color: #596268;
            }
            button {
                position: absolute;
                right: 0px;
                top: 0px;
                width: 110px;
                border: none;
                box-shadow: 0px 2px 3px #00000029;
                cursor: pointer;
                border-radius: 50px;
                background: linear-gradient(to right, #a2bf2e 0%, #c1d72d 100%);
                color: white;
                font-weight: bold;
                display: inline-block;
                vertical-align: middle;
                padding: 13px 15px;
                text-transform: uppercase;
                transition: opacity 0.3s ease;
                &:disabled, &.disabled {
                    opacity: 0.3;
                    cursor: default;
                }
            }
        }
        &.csvField {
            text-align: center;
            label {
                border-radius: 0px;
                color: white;
            }
        }
        &.jsonField {
            .button {
                position: absolute;
                top: 0px;
                right: 0px;
                border-radius: 0px;
                padding: 2px 10px;
            }
            .jsonError {
                padding: 7px;
                padding-left: 20px;
                color: red;
            }
        }
        &.imageField {
            .image-preview, .rightHandSide {
                width: 100%;
                display: inline-block;
                vertical-align: middle;
                padding: 5px;
            }
            label.button {
                border-radius: 0px;
                text-align: center;
                color: #ffffff;
                font-size: 1rem;
            }
            .rightHandSide {
                @media (min-width: $break_l) {
                    width: 30%;
                }
            }
            .image-preview {
                height: 260px;
                & > div {
                    border-radius: 3px;
                    height: 100%;
                    background-color: $backgroundLight;
                    background-size: cover;
                    background-position: center;
                }
                @media (min-width: $break_l) {
                    width: 70%;
                }
            }
        }
        &.textBuilderField {
            .textBuilderItem {
                padding: 20px 40px 20px 20px;
                border-top: 2px dashed #dedede;
                .counter {
                    position: absolute;
                    left: 0px;
                    font-weight: bold;
                    color: #b0c630;
                    font-size: 1.2rem;
                }
                img {
                    position: absolute;
                    top: 20px;
                    right: 0px;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    opacity: 0.6;
                }
            }
            .add-btn {
                width: 100%;
                display: block;
                border-radius: 0;
                text-align: center;
            }
        }
        &.checkboxField {
            padding: 8px;
            label {
                margin: 0;
                cursor: pointer;
                padding-left: 27px;
                color: $textDark;
                font-weight: normal;
                font-size: 1.1rem;
            }
            .inner {
                position: absolute;
                left: 9px;
                top: 9px;
                .box {
                    -ms-transition: background-color 0.1s ease, border-color 0.1s ease;
                    -moz-transition: background-color 0.1s ease, border-color 0.1s ease;
                    -webkit-transition: background-color 0.1s ease, border-color 0.1s ease;
                    transition: background-color 0.1s ease, border-color 0.1s ease;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                    padding: 3px;
                    background-color: white;
                    &.on {
                        background-color: $featureColor4;
                    }
                    .tick {
                        margin-bottom: 13px;
                        width: 100%;
                        -ms-transition: opacity 0.1s ease;
                        -moz-transition: opacity 0.1s ease;
                        -webkit-transition: opacity 0.1s ease;
                        transition: transform 0.1s ease;
                        transform: scale(0);
                        &.show {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
        &.numberField-new {
            padding: 8px;
            label {
                margin: 0;
                font-size: 0.9rem;
                color: $textDark;
                font-weight: normal;
                padding: 0;
                padding-left: 65px;
                width: initial;
            }
            .inner {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                .wrapper {
                    .deduct,
                    .add {
                        color: white;
                        background-color: #a31818;
                        text-align: center;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        padding: 3px 0px;
                        width: 15px;
                        font-size: 1rem;
                        border-radius: 5px 0px 0px 5px;
                    }
                    .add {
                        background-color: $featureColor2;
                        border-radius: 0px 5px 5px 0px;
                    }
                    input {
                        border-radius: 0px;
                        display: inline-block;
                        vertical-align: middle;
                        width: 25px;
                        text-align: center;
                        font-size: 1rem;
                        padding: 3px 1px;
                    }
                }
            }
        }
        &.blogContentField {
            .stackItem {
                padding: 5px;
                padding-right: 50px;
                margin-bottom: 5px;
                min-height: 150px;
                background-color: $backgroundLight;
                & > span {
                    text-align: center;
                    display: block;
                    padding: 2px;
                    background-color: #596268;
                    color: white;
                    margin-bottom: 5px;
                    border-radius: 2px;
                }
                .blog-content-editor {
                    .field {
                        margin: 0px;
                    }
                }
                .controls {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 40px;
                    & > span {
                        padding: 0px 0px 5px 0px;
                        width: 100%;
                        display: block;
                        text-align: center;
                        font-size: 0.8rem;
                     }
                    .miniBtn, select {
                        width: 100%;
                        background-color: #ffffff;
                        border-radius: 3px;
                        border: none;
                        margin-bottom: 6px;
                        text-align: center;
                        font-size: 0.8rem;
                        padding: 4px 0px;
                        cursor: pointer;
                        transition: all 0.2s ease;
                        &:hover {
                            transform: translateY(-2px);
                            box-shadow: 0px 2px 5px #afafaf;
                        }
                    }
                }
            }
            .add-section {
                text-align: center;
                padding-top: 20px;
                .button {
                    border-radius: 0px;
                    vertical-align: middle;
                }
                select {
                    padding: 10px;
                    border: none;
                    vertical-align: middle;
                }
            }
        }
        &.itemFinderField {
            .itemOptions {
                .option {
                    padding: 10px;
                    background-color: $backgroundLight;
                    margin-top: 3px;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
        &.videoField {
            iframe {
                width: 100%;
            }
        }
        &.richTextField {
            .rdw-editor-wrapper {
                background-color: #ffffff;
            }
            .rdw-editor-main {
                padding: 10px;
            }
        }
        &.recipeCombinationField {
            .slots {
                div[class^="col-"] {
                    padding: 0px;
                }
                .slot {
                    padding: 2px;
                    .slot-label {
                        padding: 10px 15px;
                        background-color: $backgroundLight;
                        text-align: center;
                        font-weight: bold;
                    }
                    .slot-inner {
                        height: 140px;
                        background-color: $backgroundLight;
                        background-position: center;
                        background-size: cover;
                        .recipe-name {
                            position: absolute;
                            width: 100%;
                            top: 50%;
                            transform: translateY(-50%);
                            text-align: center;
                            font-weight: bold;
                            background-color: $backgroundLight;
                            opacity: 0.7;
                            padding: 5px;
                            cursor: pointer;
                            &:hover {
                                opacity: 1;
                            }
                        }
                        .itemFinderField {
                            z-index: 1;
                            .itemOptions {
                                max-height: 100px;
                                overflow-y: auto;
                                .option {
                                    margin: 0px;
                                }
                            }
                        }
                    }
                    .remove-recipe {
                        position: absolute;
                        top: 2px;
                        right: 15px;
                        color: #a31818;
                        font-size: 1.8rem;
                        font-weight: bold;
                        cursor: pointer;
                        transition: color 0.2s ease;
                        &:hover {
                            color: red;
                        }
                    }
                }
            }
        }
        &.sliderField {
            padding: 10px;
            border-radius: 10px;
            border: 2px solid transparent;
            &.bad {
                border: 2px solid #ff00000f;
            }
            .field-errors {
                text-align: center;
                & > div {
                    transition: height 0.15s ease;
                    color: #e38a8a;
                    margin-top: 10px;
                    overflow: hidden;
                }
            }
            .slider-wrapper {
                padding: 0px;
                @media (min-width: $break_s) {
                    padding: 0px 100px;
                }
                .label {
                    position: absolute;
                    background-color: #eae9e4;
                    border-radius: 30px;
                    color: $textDark;
                    font-size: 0.7rem;
                    padding: 5px 10px;
                    top: -8px;
                    width: 94px;
                    text-align: center;
                }
                .label-left {
                    left: 0px;
                }
                .label-right {
                    right: 0px;
                }
            }
            .slider {
                .main-bar {
                    width: 100%;
                    height: 7px;
                    background-color: #eae9e4;
                    border-radius: 5px;
                }
                .range-bar {
                    height: 7px;
                    background-color: $featureColor1;
                    border-radius: 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: all 0.15s ease;
                    &.hidden {
                        opacity: 0;
                    }
                }
                .circle {
                    width: 31px;
                    height: 31px;
                    position: absolute;
                    top: -12px;
                    cursor: pointer;
                    z-index: 1;
                    transition: all 0.15s ease;
                    &.hidden {
                        transform: scale(0);
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            .scale {
                margin-top: -29px;
                .value {
                    background-color: transparent;
                    padding-top: 29px;
                    .line {
                        width: 2px;
                        height: 20px;
                        margin: 0 auto 1px auto;
                        background-color: #eae9e4;
                    }
                    font-weight: bold;
                    color: $textDark;
                }
            }
        }
    }
    .toggleButton {
        color: white;
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        padding: 10px 15px;
        padding-right: 40px;
        box-shadow: 0px 2px 3px #00000029;
        cursor: pointer;
        border-radius: 50px;
        background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
        .outerCircle {
            position: absolute;
            top: 5.5px;
            right: 5px;
            width: 30px;
            height: 30px;
            padding: 3px;
            border-radius: 50%;
            background: linear-gradient(to right, rgba(200,224,40,1) 0%,rgba(162,191,46,1) 100%);
            transform: rotate(-45deg);
            .innerCircle {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                padding: 5px;
                background: linear-gradient(to right, rgba(200,224,40,1) 0%,rgba(122,153,36,1) 100%);
                img {
                    width: 100%;
                    margin-bottom: 8px;
                    transition: opacity 0.2s ease;
                    transform: rotate(45deg);
                    transform-origin: center;
                    opacity: 0;
                }
            }
        }
        &.selected {
            background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(252,190,0,1) 100%);
            .outerCircle {
                background: linear-gradient(to right, rgba(252,205,35,1) 0%,rgba(240,145,21,1) 100%);
                .innerCircle {
                    background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(204,89,6,1) 100%);
                    img {
                        opacity: 1;
                    }
                }
            }
        }
        &.disabled {
            opacity: 0.3;
            cursor: default;
        }
        &.wide {
            width: 100%;
        }
        @media (min-width: $break_xs) {
            padding-right: 55px;
        }

    }
    .price-badge {
        color: white;
        padding: 30px;
        height: 100px;
        width: 100px;
        border-radius: 50px;
        background: linear-gradient(to right, #a2bf2e 0%, #c1d72d 100%);
        border: 3px solid white;
        box-shadow: 0px 3px 10px #00000040;
        .badge-label {
            display: block;
            font-weight: normal;
            position: absolute;
            font-size: 0.8rem;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .price {
                font-size: 2.5rem;
                font-weight: bold;
                margin: -10px 0px;
            }
        }
    }
    .price-badge {
        width: 140px;
    }
    .pageChanger {
        margin-top: 15px;
        text-align: center;
        .pageDrop {
            margin: 0px 10px;
        }
        .left,
        .right {
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.2s ease;
            &.disabled {
                opacity: 0.5;
                cursor: initial;
            }
        }
    }
    .barChart {
        height: 150px;
        width: 100%;
        padding-left: 40px;
        padding-bottom: 20px;
        .scale {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 35px;
            width: 40px;
            .value {
                color: $textDark;
                font-weight: bold;
                position: absolute;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                .line {
                    height: 2px;
                    width: 20px;
                    background-color: #eae9e4;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }
            }
        }
        .graph {
            height: 100%;
            border-left: 2px solid #eae9e4;
            border-bottom: 2px solid #eae9e4;
            padding: 0px 0px 15px 0px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .bar {
                margin-left: 15px;
                border-radius: 5px 5px 0px 0px;
                .number {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    color: white;
                    background-color: $textDark;
                    position: absolute;
                    top: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                    padding-top: 5px;
                    font-weight: bold;
                }
                .label {
                    font-weight: bold;
                    text-align: center;
                    position: absolute;
                    bottom: -40px;
                    width: 100%;
                    color: $textDark;
                }
            }
        }
    }


//***********************REUSABLE CLASSES*******************************
.shadow1 {
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4);
}
.shadow2 {
    -webkit-box-shadow: 0px 2px 3px #00000029;
    -moz-box-shadow: 0px 2px 3px #00000029;
    box-shadow: 0px 2px 3px #00000029;
}
.embededLink {
    color: $featureColor4;
    font-weight: bold;
}
.customModal {
    display: none;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
    .inner {
        background-color: #f4f5f5;
        border-radius: 20px;
        width: 95%;
        max-width: 800px;
        margin: 0 auto;
        top: 50%;
        padding: 15px;
        transform: translateY(-50%);
        .content {
            background-color: #303c49;
            padding: 30px;
            border-radius: 10px;
            color: #f4f5f5;
            .closeModal {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                width: 25px;
            }
            .heading {
                color: white;
                font-weight: bold;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    width: 25px;
                    margin: 0px 10px 5px 0px;
                }
            }
            p {
                a {
                    text-decoration: none;
                }
            }
        }
    }
}
.fullWidth {
    width: 100%;
}
.button {
    display: inline-block;
    cursor: pointer;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    background-color: $featureColor2;
    transition: background-color 0.2s ease;
    padding: 10px 28px;
    border: none;
    border-radius: 50px;
    &:hover {
        background-color: #9fb32e;
        color: white;
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
}
.formField {
    margin-bottom: 20px;
}
.logoForPrint {
    display: none;
    @media print {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        img {
            width: 60px;
            height: 60px;
        }
    }
}
label {
    font-weight: bold;
    color: white;
    margin-left: 15px;
    margin-bottom: 5px;
    display: block;
    width: 100%;
}
.imageFrame {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    img {
        width: 100%;
    }
    @media (min-width: $break_xs) {
        padding: 15px;
    }
}
.textLink {
    padding-left: 5px;
    color: $featureColor2;
    font-weight: bold;
    transition: color 0.2s ease;
    &:hover {
        color: #9fb32e;
    }
}
.recipe-col {
    margin-bottom: 30px;
    text-align: left;
    .inner {
        padding: 20px;
        padding-bottom: 61px;
        height: 100%;
        max-width: 400px;

        margin: 0 auto;
        border-radius: 3px;
        background-color: white;
        .mealImage {
            width: 100%;
            height: 250px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            @media (min-width: $break_l) {
                height: 210px;
            }
            @media (min-width: $break_xl) {
                height: 250px;
            }
        }
        .name {
            color: $featureColor4;
            font-size: 2.2rem;
            font-family: 'Dancing Script', cursive;
            padding: 10px 0px;
            font-weight: bold;
        }
        .description {
            text-align: justify;
            padding-bottom: 20px;
            font-size: 1rem;
            color: $textDark;
            @media (min-width: $break_xl) {
                font-size: 1.2rem;
            }
        }
        .viewRecipe {
            position: absolute;
            bottom: 20px;
            left: 20px;
        }
    }
}
.tab-selector {
    position: absolute;
    left: 0;
    width: 100%;
    .tab {
        display: inline-block;
        vertical-align: top;
        background-color: rgba(22, 20, 32, 0.9);
        color: white;
        text-align: center;
        font-size: 0.8rem;
        font-family: 'Roboto', sans-serif;
        padding: 10px 0px;
        cursor: pointer;
        border-radius: 6px 6px 0px 0px;
        transition: all 0.2s ease;
        text-decoration: none;
        span {
            display: block;
        }
        &.selected {
            background-color: $backgroundLight;
            color: $heroText;
        }
    }
}
input,
textarea {
    display: block;
    width: 100%;
    border-radius: 30px;
    padding: 10px 15px;
    background-color: white;
    border: 0;
    outline: 0;
    color: $textDark;
    &::placeholder {
        color: $textLight;
    }
    &:active {
        outline: 0;
    }
}
.splitField {
    width: 100%;
    @media (min-width: $break_s) {
        .formField {
            display: inline-block;
            vertical-align: middle;
            width: 48%;
            &:first-child {
                margin-right: 4%;
            }
        }
    }
}
.dropdown {
    color: $textDark;
    display: inline-block;
    min-width: 130px;
    .currentValue {
        border-bottom: 1px solid #e2e2e2;
        border-radius: 3px;
        text-align: center;
        padding: 10px 15px;
        cursor: pointer;
        background-color: white;
        font-weight: bold;
        transition: all 0.2s ease;
        .chevron {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            transition: transform 0.2s ease;
            transform: rotate(90deg);
        }
        &.open {
            box-shadow: 0px 1px 1px #00000036;
            transform: translateY(-2px);
            .chevron {
                transform: rotate(-90deg);
            }
        }
    }
    .options {
        text-align: center;
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
        z-index: 3;
        overflow-x: hidden;
        border-radius: 3px;
        box-shadow: 0px 1px 1px #00000036;
        .inner {
            max-height: 148px;
            overflow-y: scroll;
            // margin-right: -17px;
        }
        .option {
            cursor: pointer;
            background-color: #fff;
            transition: all 0.2s ease;
            height: 0;
            overflow: hidden;
            line-height: 37px;
            &:hover {
                background-color: $backgroundLight;
            }
        }
        &.open {
            .option {
                height: 37px;
            }
        }
    }
}
.testimonials {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 0;
    padding: 60px 0px 50px 0px;
    .testimonialsBefore {
        * {
            font-weight: lighter;
        }
    }
    .testimonial {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        white-space: initial;
        padding: 0px 20px;
        .text {
            color: $textDark;
            font-style: italic;
            font-weight: 200;
            line-height: 2rem;
        }
        .author {
            color: $featureColor2;
            font-family: 'Dancing Script', cursive;
            font-weight: bold;
            font-size: 2rem;
            margin-top: 15px;
        }
    }
    @media (min-width: $break_xs) {
        padding: 80px 0px 70px 0px;
    }
    @media (min-width: $break_s) {
        background-size: cover;
    }
    @media (min-width: $break_l) {
        .testimonial {
            padding: 0px 30px;
            .text {
                font-size: 1.2rem;
                line-height: 2.5rem;
            }
            .author {
                margin-top: 30px;
                font-size: 3rem;
            }
        }
    }
    @media (min-width: $break_xl) {
        padding: 90px 0px 80px 0px;
        .testimonial {
            .text {
                font-size: 1.3rem;
            }
            .author {
                font-size: 4.5rem;
            }
        }
    }
    @media (min-width: $break_xxl) {
        padding: 110px 0px 100px 0px;
        .testimonial {
            .text {
                font-size: 1.4rem;
            }
        }
    }
    @media (min-width: $break_xxxl) {
        padding: 130px 0px 120px 0px;
    }
}
.textSection,
.darkSection {
    padding: 40px 0px;
    .sectionTitle,
    h1 {
        color: $featureColor4;
        font-size: 2rem;
        margin-bottom: 20px;
        font-weight: 200;
    }
    .titleSmall,
    h4 {
        font-weight: bold;
        color: $featureColor3;
        font-size: 1.2rem;
        margin-bottom: 14px;
    }
    .sectionText,
    p,
    li {
        color: $textDark;
        @media (min-width: $break_xl) {
            font-size: 1.2rem;
        }
        a {
            text-decoration: none;
        }
    }
    ul {
        padding-left: 20px;
        li {
            margin-bottom: 5px;
        }
    }
    .button {
        margin-top: 20px;
    }
    @media (min-width: $break_xs) {
        padding: 50px 0px;
    }
    @media (min-width: $break_l) {
        padding: 60px 0px;
    }
    @media (min-width: $break_xl) {
        padding: 70px 0px;
    }
    @media (min-width: $break_xxl) {
        padding: 80px 0px;
    }
}
.darkSection {
    background-color: #3c444c;
    .sectionText,
    p,
    li {
        color: #f4f5f5;
        font-weight: 200;
        opacity: 0.8;
    }
}
.iconBtn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease;
    &:hover {
        opacity: 1;
    }
}
.carousel {
    .chevron {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 2;
        opacity: 0.7;
        transition: opacity 0.2s ease;
        &:hover {
            opacity: 1;
        }
        &.left {
            left: -15px;

        }
        &.right {
            right: -15px;
        }
        @media(min-width: $break_s) {
            &.left {
                left: -30px;

            }
            &.right {
                right: -30px;
            }
        }
        @media(min-width: $break_l) {
            &.left {
                left: -20px;

            }
            &.right {
                right: -20px;
            }
        }
        @media(min-width: $break_xl) {
            &.left {
                left: -40px;

            }
            &.right {
                right: -40px;
            }
        }
    }
    .outer {
        overflow-x: hidden;
        padding-bottom: 3px;
        .items {
            white-space: nowrap;
            transition: transform 0.15s ease;
        }
    }
}

.upgradeBox {
    padding: 30px 0px;
    min-height: 284px;
    .emailField {
        background-color: white;
        overflow: hidden;
        border-radius: 10px;
        .green,
        .orange {
            color: white;
            padding: 15px;
            font-weight: bold;
            text-align: center;
            transition: all 0.2s ease;
            opacity: 1;
            &.grayed {
                opacity: 0.3;
            }
        }
        .green {
            background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
        }
        .orange {
            background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(252,190,0,1) 100%);
        }
        .inner {
            img {
                display: inline-block;
                vertical-align: middle;
                width: 19px;
                height: 19px;
                position: absolute;
                z-index: 1;
                &.closeImg {
                    right: 15px;
                    top: 15px;
                    opacity: 0.5;
                    transition: opacity 0.2s ease;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    }
                }
                &.userImg {
                    left: 10px;
                    top: 15px;
                }
            }
            input {
                display: inline-block;
                vertical-align: middle;
                padding: 15px 40px;
                border-radius: 0px;
            }
        }
    }
    .paymentField {
        overflow: hidden;
        box-shadow: 0px 2px 3px #00000029;
        border-radius: 10px;
        .inner {
            .cardField {
                margin: 0;
                .inner {
                    padding-right: 0px;
                    .StripeElement {
                        padding: 12px 20px;
                        display: block;
                        width: 100%;
                        background-color: white;
                        outline: 0;
                        height: 41px;
                        color: #596268;
                        border: 0;
                        box-shadow: none;
                        border-radius: 0px;
                    }
                    button {
                        position: relative;
                        display: block;
                        width: 100%;
                        border: none;
                        box-shadow: none;
                        cursor: pointer;
                        border-radius: 0px;
                        background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                        color: white;
                        font-weight: bold;
                        padding: 10px 15px;
                        text-transform: uppercase;
                        transition: opacity 0.3s ease;
                        text-align: center;
                    }
                }
            }
        }
    }
    .message {
        border-radius: 10px;
        text-align: center;
        padding: 15px 15px;
        margin: 20px 0px;
        background-color: #e6e6de;
        color: $textDark;
        a {
            color: $featureColor2;
            font-weight: bold;
            // padding: 0px 3px;
        }
        &.dark {
            color: #f4f5f5;
            opacity: 0.8;
            background-color: #65646b;
        }
    }
    @media (min-width: $break_xs) {
        min-height: 182px;
        .emailField {
            border-radius: 30px;
            padding-left: 120px;
            .green,
            .orange {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 120px;
            }
        }
        .paymentField {
            border-radius: 30px;
            .inner {
                .cardField {
                    .inner {
                        padding-right: 120px;
                        .StripeElement {
                            padding: 12px 20px;
                            display: block;
                            width: 100%;
                            background-color: white;
                            outline: 0;
                            height: 41px;
                            color: #596268;
                            border: 0;
                            box-shadow: none;
                            border-radius: 0px;
                        }
                        button {
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            width: 120px;
                        }
                    }
                }
            }
        }
        .message {
            border-radius: 30px;
        }
    }
}


//*************************HEADER CLASSES*******************************
header {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: white;
    .top-line {
        display: none;
        background-color: $featureColor2;
        padding: 7px 30px;
        box-shadow: inset 0px -1px 7px -2px rgba(0,0,0,0.35);
        .left {
            opacity: 0.7;
            display: inline-block;
            width: 40%;
            text-align: left;
        }
        .right {
            display: inline-block;
            width: 60%;
            text-align: right;
        }
        @media (min-width: $break_s) {
            display: block;
        }
        .email {
            display: inline-block;
            vertical-align: middle;
            margin-right: 30px;
            a {
                opacity: 0.7;
                text-decoration: none;
                color: white;
                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        .userSummary {
            cursor: default;
            img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
            .logout {
                display: inline-block;
                cursor: pointer;
                margin-left: 15px;
            }
        }
        .smicons {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            a {
                opacity: 0.7;
                margin: 0px 7px;
                text-decoration: none;
                img {
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    .nav-links {
        background-color: $featureColor1;
        border-bottom: 1px solid #a7ba3b;
        padding: 10px 30px;
        font-size: 0;
        @media (min-width: $break_l) {
            padding: 0px 30px;
            padding-right: 27px;
        }
        @media (min-width: $break_xl) {
            padding-right: 17px;
        }
        .logo {
            display: inline-block;
            vertical-align: middle;
            width: 50%;
            @media (min-width: $break_l) {
                width: 6%;
            }
            img {
                display: inline-block;
                vertical-align: middle;
                width: 40px;
                height: 40px;
                margin-right: 10px
            }
        }
        .mobile-btn {
            display: inline-block;
            vertical-align: middle;
            width: 50%;
            text-align: right;
            .inner {
                display: inline-block;
                cursor: pointer;
                span {
                    font-size: 1.1rem;
                    padding-right: 30px;
                    display: inline-block;
                    vertical-align: middle;
                }
                .dots {
                    display: inline-block;
                    vertical-align: middle;
                    height: 40px;
                    .dot {
                        background-color: white;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        position: absolute;
                        right: 0px;
                        transition: top 0.2s ease;
                        &.top {
                            top: 0px;
                        }
                        &.mid {
                            top: 15px;
                        }
                        &.bot {
                            top: 30px;
                        }
                    }
                    &.opened {
                        .top,
                        .bot {
                            top: 15px;
                        }
                    }
                }
            }
        }
        .desktop-links {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: right;
            display: inline-block;
            vertical-align: middle;
            width: 94%;
            .has-dropdown {
                &.isOpen {
                    .subLinks {
                        &.center {
                            transform: translateX(-50%) scale(1,1);
                        }
                        &.right {
                                transform: scale(1,1);
                        }
                    }
                }
            }
            // & > li:hover {
            //     &.has-dropdown:after {
            //         content: "";
            //         position: absolute;
            //         background-color: #97ae23;
            //         bottom: -10px;
            //         left: 50%;
            //         transform: translateX(-50%) rotate(45deg);
            //         height: 15px;
            //         width: 15px;
            //     }
            //     .subLinks {
            //         &.center {
            //             transform: translateX(-50%) scale(1,1);
            //         }
            //         &.right {
            //             transform: scale(1,1);
            //         }
            //     }
            // }

            & > li {
                padding: 14px 10px;
                display: inline-block;
                vertical-align: middle;
                font-size: 1rem;
                @media (min-width: $break_l) {
                    font-size: 0.9rem;
                    padding: 14px 0px;
                }
                @media (min-width: $break_xl) {
                    font-size: 1rem;
                }
                span,
                a {
                    cursor: pointer;
                    display: block;
                    color: white;
                    text-decoration: none;
                    padding: 0px 5px;
                    transition: color 0.15s ease;
                    @media (min-width: $break_l) {
                        padding: 0px 12px;
                    }
                    @media (min-width: $break_xl) {
                        padding: 0px 20px;
                    }
                    &.active,
                    &:hover {
                        color: #7f9917;
                    }
                }
                .subLinks {
                    transform-origin: 50% 0%;
                    padding: 0;
                    list-style: none;
                    background-color: #97ae23;
                    position: absolute;
                    top: 47px;
                    z-index: 1;
                    transition: transform 0.2s ease;
                    &.center {
                        left: 50%;
                        transform: translateX(-50%) scale(1,0);
                    }
                    &.right {
                        transform: scale(1,0);
                        @media (min-width: $break_s) {
                            right: -27px;
                        }
                        @media (min-width: $break_l) {
                            right: --22px;
                        }
                        @media (min-width: $break_xl) {
                            right: -17px;
                        }
                    }
                    @media (min-width: $break_xl) {
                        top: 50px;
                    }
                    .subLink {
                        text-align: left;
                        display: inline-block;
                        width: 190px;
                        a {
                            padding: 13px 5px 13px 25px;
                            opacity: 0.8;
                            transition: opacity 0.2s ease;
                            &:hover {
                                color: white;
                                opacity: 1;
                            }
                        }
                        .sublinkIcon {
                            display: inline-block;
                            vertical-align: middle;
                            width: 15px;
                            margin-left: 10px;
                        }
                        &.component {
                            padding: 15px 17px;
                        }
                        &.login {
                            text-align: center;
                            width: 100vw;
                            max-width: 300px;
                        }
                        &.buttonLink {
                            text-align: center;
                            width: 100%;
                            padding: 0px 20px 25px 20px;
                            .linkButton {
                                background-color: $featureColor2;
                                border-radius: 25px;
                                img {
                                    position: absolute;
                                    top: 16px;
                                    right: 15px;
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mobile-links {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: left;
            position: absolute;
            top: 60px;
            left: 0px;
            width: 100%;
            background-color: $featureColor1;
            overflow-y: hidden;
            z-index: 6;
            display: none;
            @media (min-width: $break_s) {
                top: 50px;
            }
            li {
                font-size: 1.2rem;
                span,
                a {
                    cursor: pointer;
                    display: block;
                    color: white;
                    text-decoration: none;
                    padding: 10px 20px;
                    transition: color 0.15s ease;
                    &.active,
                    &:hover {
                        color: #7f9917;
                    }
                }
                .subLinks {
                    display: none;
                    display: block;
                    padding: 0;
                    list-style: none;
                    background-color: #97ae23;
                    z-index: 1;
                    .subLink {
                        text-align: left;
                        display: block;
                        padding: 5px;
                        padding-left: 15px;
                        a {
                            font-size: 1rem;
                            transition: opacity 0.2s ease;
                            opacity: 0.8;
                            img {
                                display: inline-block;
                                vertical-align: middle;
                                width: 15px;
                                margin-left: 10px;
                            }
                            &:hover {
                                opacity: 1;
                                color: white;
                            }
                        }
                        .chevron {
                            margin-left: 20px;
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            & > li > .subLinks {
                overflow-y: hidden;
                & > li {
                    height: 0px;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    transition: all 0.2s ease;
                }
            }
            & > li.isOpen > .subLinks > li {
                height: initial;
                &.buttonLink {
                    text-align: center;
                    height: 100px;
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                    margin-top: 10px;
                    .linkButton {
                        background-color: $featureColor2;
                        border-radius: 25px;
                    }
                }
            }
        }
    }
    @media print {
        display: none;
    }
}

//*************************FOOTER CLASSES*******************************
footer {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    // height: 692px;
    background-color: $featureColor3;
    padding: 30px 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: white;
    // @media (min-width: $break_xs) {
    //     height: 570px;
    // }
    @media (min-width: $break_s) {
        padding: 30px 0px;
        // height: 400px;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 15px;
        @media (min-width: $break_xs) {
            display: inline-block;
            vertical-align: top;
        }
        li {
            a {
                color: white;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    color: white;
                }
            }
        }
    }
    .logo {
        margin-top: 20px;
        text-align: center;
        @media (min-width: $break_s) {
            margin: 0;
            text-align: right;
        }
        img {
            width: 50px;
            height: 50px;
            display: inline-block;
        }
    }
    .links {
        font-size: 1rem;
        a {
            opacity: 0.7;
            &:hover {
                opacity: 1;
            }
        }
    }
    .disclaimer {
        text-align: justify;
        opacity: 0.7;
        b,
        strong {
            font-size: 1rem;
            display: block;
            margin-bottom: 10px;
        }
        p {
            font-size: 0.7rem;
        }
    }
    @media print {
        display: none;
    }
}

//*************************PAGE CLASSES*******************************
.page {
    .hero {
        width: 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: auto 150%;
        padding: 50px 0px 40px 0px;
        .hero-content {
            img {
                width: 100%;
            }
            h2 {
                width: 100%;
                text-align: center;
                font-family: 'Dancing Script', cursive;
                font-weight: bold;
                font-size: 3rem;
                color: #35424a;
            }
            .logo {
                width: 75px;
                height: 75px;
                margin: 0 auto 15px auto;;
            }
            .textImg {
                width: 90%;
                max-width: 700px;
                margin: 0 auto 15px auto;
            }
            .heroText {
                text-align: center;
                width: 80%;
                max-width: 600px;
                margin: 0 auto;
                font-size: 1.3rem;
                color: $heroText;
                font-family: 'Roboto', sans-serif;
                font-weight: 200;
            }
            .button-row {
                text-align: center;
                margin-top: 30px;
                .button {
                    margin: 0px 5px 10px 5px;
                    // height: 41px;
                    &.orange {
                        background-color: $featureColor3;
                    }
                    &.blue {
                        background-color: $featureColor4;
                    }
                    img {
                        display: inline-block;
                        width: 23px;
                        height: 23px;
                        margin-left: 5px;
                    }
                }
            }
            .freeTrial {
                display: block;
                width: 125px;
                margin: 10px auto 0px auto;
                img {
                    width: 100%;
                }
            }
        }
        @media (min-width: $break_xs) {
            padding: 60px 0px 50px 0px;
            background-size: auto 140%;
            .hero-content {
                h2 {
                    font-size: 3.8rem;
                }
                .logo {
                    width: 125px;
                    height: 125px;
                    margin-bottom: 20px;
                }
                .textImg {
                    margin-bottom: 25px;
                }
                .heroText {
                    font-size: 1.7rem;
                }
            }
        }
        @media (min-width: $break_s) {
            padding: 70px 0px 60px 0px;
            .hero-content {
                h2 {
                    font-size: 4.5rem;
                }
                .logo {
                    width: 150px;
                    height: 150px;
                    margin-bottom: 25px;
                }
                .textImg {
                    margin-bottom: 30px;
                }
                .heroText {
                    font-size: 2rem;
                }
                .freeTrial {
                    width: 150px;
                }
            }
        }
        @media (min-width: $break_l) {
            padding: 70px 0px 60px 0px;
            background-size: auto 135%;
            .hero-content {
                h2 {
                    font-size: 5rem;
                }
                .logo {
                    width: 175px;
                    height: 175px;
                }
                .textImg {
                    margin-bottom: 35px;
                }
                .heroText {
                    font-size: 2.3rem;
                }
                .freeTrial {
                    margin: 0px;
                    position: absolute;
                    bottom: 50px;
                    right: 5%;
                }
            }
        }
        @media (min-width: $break_xl) {
            padding: 80px 0px 70px 0px;
            background-size: auto 165%;
            background-position: center 70%;
            .hero-content {
                h2 {
                    font-size: 5.3rem;
                }
                .freeTrial {
                    right: 10%;
                }
            }
        }
        @media (min-width: $break_xxl) {
            padding: 90px 0px 80px 0px;
            .hero-content {
                .freeTrial {
                    right: 15%;
                }
            }
        }
        @media (min-width: 1700px) {
            padding: 110px 0px 110px 0px;
            .hero-content {
                .freeTrial {
                    right: 20%;
                }
            }
        }
        @media (min-width: 2000px) {
            background-size: cover;
        }
        @media print {
            display: none;
        }
    }
    .tsAndCs {
        padding: 10px 10px;
        color: #e4e4e4;
        text-align: center;
        // border-bottom: 2px dashed #6b6b6b;
        & > span {
            padding-top: 5px;
            display: inline-block;
            vertical-align: middle;
            a {
                color: $featureColor2;
                &:hover {
                    color: $featureColor2;
                    text-decoration: underline;
                }
            }
        }
        .checkboxField {
            vertical-align: middle;
            display: inline-block;
        }
    }
    .tile-row {
        .tile {
            width: 100%;
            height: 300px;
            background-size: cover;
            .bg {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .tile-content {
                position: absolute;
                top: 50%;
                left: 10%;
                right: 10%;
                transform: translateY(-50%);
                h2 {
                    color: white;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 200;
                    font-size: 1.8rem;
                    p {
                        margin-bottom: 0px;
                    }
                }
                .imageText {
                    width: 100%;
                    max-width: 700px;
                    img {
                        width: 100%;
                    }
                }
                .button {
                    font-size: 0.8rem;
                    margin-top: 15px;
                    img {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0px 0px 3px 10px;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            @media (min-width: $break_xs) {
                height: 500px;
                .tile-content {
                    left: 15%;
                    right: 15%;
                    h1 {
                        font-size: 2.5rem;
                    }
                    .button {
                        font-size: 1rem;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            @media (min-width: $break_s) {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                height: 350px;
                .tile-content {
                    h2 {
                        font-size: 2rem;
                    }
                }
            }
            @media (min-width: $break_l) {
                height: 450px;
                .tile-content {
                    h2 {
                        font-size: 2.5rem;
                    }
                }
            }
            @media (min-width: $break_xl) {
                height: 550px;
                .tile-content {
                    h2 {
                        font-size: 3rem;
                    }
                    .button {
                        font-size: 1.2rem;
                        img {
                            width: 23px;
                            height: 23px;
                        }
                    }
                }
            }
            @media (min-width: $break_xxl) {
                height: 650px;
                .tile-content {
                    h2 {
                        font-size: 3.5rem;
                    }
                }
            }
            @media (min-width: $break_xxxl) {
                height: 750px;
            }
        }
    }
    &.homePage {
        .hero {
            background-size: auto 135%;
            .hero-content {
                .textImg {
                    text-align: center;
                    img {
                        width: 100px;
                    }
                    h1 {
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                        font-size: 3rem;
                        color: #35424a;
                        span {
                            display: block;
                            font-size: 2rem;
                        }
                    }
                }
            }
            @media (min-width: $break_xs) {
                background-size: auto 125%;
                .hero-content {
                    .textImg {
                        img {
                            width: 150px;
                        }

                        h1 {
                            font-size: 4rem;
                            span {
                                font-size: 2.5rem;
                            }
                        }
                    }
                }
            }
            @media (min-width: $break_s) {
                .hero-content {
                    .textImg {
                        h1 {
                            font-size: 5.5rem;
                            span {
                                font-size: 3rem;
                            }
                        }
                    }
                }
            }
            @media (min-width: $break_l) {
                .hero-content {
                    .textImg {
                        h1 {
                            font-size: 7rem;
                            span {
                                font-size: 4rem;
                            }
                        }
                    }
                }
            }
            @media (min-width: $break_xl) {
                background-size: auto 135%;
            }
            @media (min-width: $break_xxl) {
                background-size: auto 145%;
            }
            @media (min-width: 2300px) {
                background-size: cover;
            }
        }
        .stats {
            padding: 45px 0px 75px 0px;
            background-color: white;
            box-shadow: 0px 0px 6px #00000040;
            z-index: 1;
            .row > div {
                padding: 20px 10px;
                text-align: center;
                .number {
                    font-family: 'Dancing Script', cursive;
                    font-size: 5rem;
                    color: $featureColor4;
                    margin-bottom: -5px;
                }
                .label {
                    font-size: 1.2rem;
                    color: $textLight;

                }
            }
        }
        .dark-section {
            padding: 50px 0px;
            background-color: $backgroundDark;
            .infoBlocks {
                font-size: 0;
            }
            .contentBlock {
                padding: 10px 15px;
                padding-left: 45px;
                display: inline-block;
                width: 100%;
                vertical-align: top;
                @media (min-width: $break_xs) {
                    width: 50%;
                }
                img {
                    position: absolute;
                    left: 0;
                    top: 10px;
                    width: 30px;
                    height: 30px;
                }
                p {
                    color: #f4f5f5;
                    margin: 0px;
                    font-size: 1rem;
                    a {
                        color: $featureColor1;
                        text-decoration: none;
                        font-weight: bold;
                    }
                }
            }
            .blueText {
                font-family: 'Dancing Script', cursive;
                color: $featureColor4;
                font-size: 3.5rem;
                width: 100%;
                float: left;
                padding: 15px;
                font-weight: bold;
                // img {
                //     width: 100%;
                //     max-width: 300px;
                //     height: auto;
                // }
            }
            .bottomText {
                color: #f4f5f5;
                margin-top: 20px;
                * {
                    font-weight: lighter;
                }
                strong {
                    font-weight: bold;
                }
            }
            @media (min-width: $break_xs) {
                .contentBlock, .blueText {
                    width: 50%;
                }
            }
            @media (min-width: $break_xl) {
                .contentBlock, .blueText {
                    width: 25%;
                    p {
                        font-size: 1.2rem;
                    }
                }
                .bottomText {
                    font-size: 2.1rem;
                    line-height: 3rem;
                }
            }
        }
        .textSection {
            &.topSection {
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: left 100%;
                background-size: 0;
                .videoSection {
                    .video-wrapper {
                        iframe {
                            height: 200px;
                        }
                    }
                }
                @media (min-width: $break_s) {
                    background-size: 100%;
                    .sectionTitle,
                    h1 {
                        font-size: 2.3rem;
                        text-align: right;
                    }
                }
                @media (min-width: $break_l) {
                    padding: 60px 0px;
                    .videoSection {
                        text-align: right;
                        .video-wrapper {
                            display: inline-block;
                            width: 85%;
                        }
                    }
                    .sectionTitle,
                    h1 {
                        font-size: 2.5rem;
                    }
                }
                @media (min-width: $break_xl) {
                    background-position: left bottom;
                    padding: 70px 0px;
                    .sectionTitle,
                    h1 {
                        font-size: 2.5rem;
                    }
                    .sectionText,
                    p,
                    li {
                        font-size: 1.2rem;
                    }
                }
                @media (min-width: $break_xxl) {
                    // background-position: left 60%;
                    padding: 80px 0px;
                }
                .button {
                    margin: 20px 10px 0px 10px;
                }
            }
            &.longSection {
                background-repeat: no-repeat;
                background-size: 0;
                background-color: white;
                .firstCol {
                    margin-bottom: 40px;
                }
                .secondCol {
                    .button {
                        margin-bottom: 15px;
                    }
                }
                .button {
                    margin-top: 5px;
                }
                .sectionTitle,
                h1 {
                    margin-bottom: 10px;
                }
                .titleSmall,
                h4 {
                    margin-bottom: 15px;
                }
                @media (min-width: $break_s) {
                    background-position: 50% 100%;
                    background-size: cover;
                }
                @media (min-width: $break_l) {
                    background-position: 50% 50%;
                }
                @media (min-width: $break_xl) {
                    background-size: 80%;
                    background-position: 100% 50%;
                }
                @media (min-width: $break_xxl) {
                    background-size: 70%;
                }
                @media (min-width: $break_xxxl) {
                    background-size: 60%;
                }
            }
            &.bottomSection {
                background-color: white;
                padding-top: 0px;
                .sectionTitle,
                h1 {
                    margin-bottom: 10px;
                }
                .titleSmall,
                h4 {
                    margin-bottom: 15px;
                }
                @media (min-width: $break_s) {
                    .imageCol {
                        display: block;
                        .almonds {
                            background-position: right;
                            background-size: contain;
                            background-repeat: no-repeat;
                            width: 100%;
                            height: 300px;
                        }
                    }
                }
                @media (min-width: $break_l) {
                    .imageCol {
                        .almonds {
                            height: 420px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        .testimonialsCarousel {
            .chevron {
                width: 30px;
                height: 30px;
                @media(min-width: $break_xs) {
                    &.left {
                        left: -30px;

                    }
                    &.right {
                        right: -30px;
                    }
                }
                @media(min-width: $break_s) {
                    &.left {
                        left: -40px;

                    }
                    &.right {
                        right: -40px;
                    }
                }
                @media(min-width: $break_l) {
                    &.left {
                        left: -30px;

                    }
                    &.right {
                        right: -30px;
                    }
                }
                @media(min-width: $break_xl) {
                    &.left {
                        left: -40px;

                    }
                    &.right {
                        right: -40px;
                    }
                }
            }
        }
        .homePageVid {
            margin-bottom: 30px
        }
    }
    &.aboutPage {
        background-color: $backgroundLight;
        .tab-selector {
            top: -81px;
            .tab {
                padding: 10px 15px;
                font-size: 1rem;
                &:first-child {
                    margin-right: 10px;
                }
            }
            @media (min-width: $break_xs) {
                top: -91px;
            }
            @media (min-width: $break_l) {
                top: -105px;
                .tab {
                    font-size: 1.2rem;
                }
            }
            @media (min-width: $break_xl) {
                top: -115px;
            }
            @media (min-width: $break_xxl) {
                top: -125px;
            }
        }
        .program {
            text-align: center;
            padding: 30px 0px;
            .button {
                margin: 0px 0px 20px 0px;
            }
        }
        .imageFrame {
            margin-bottom: 15px;
            @media (min-width: $break_s) {
                &.floatedLeft {
                    float: left;
                    margin: 0px 20px 20px 0px;
                }
                &.floatedRight {
                    float: right;
                    margin: 0px 0px 20px 20px;
                }
                &.small {
                    width: 40%;
                }
                &.medium {
                    width: 50%;
                }
            }
        }
        .addSpace {
            @media (min-width: $break_s) {
                ul {
                    margin-left: calc(50% + 20px);
                }
            }
        }
        .sectionText {
            &.quote {
                font-style: italic;
                font-weight: bold;
            }
            .author {
                font-style: normal;
                font-size: 2.2rem;
                font-family: 'Dancing Script', cursive;
                display: block;
                color: $featureColor2;
                font-weight: bold;
            }
        }
        .button {
            color: white;
            &:hover {
                color: white;
            }
        }
        .titleSmall {
            margin-bottom: 20px;
        }
        .team {
            padding-top: 30px;
            .person {
                padding-bottom: 25px;
                max-width: 300px;
                margin: 0 auto;
                .imageFrame {
                    .image {
                        height: 250px;
                        background-position: top;
                        background-size: cover;
                        background-repeat: no-repeat;
                        @media (min-width: $break_xs) {
                            height: 190px;
                        }
                        @media (min-width: $break_s) {
                            height: 250px;
                        }
                        @media (min-width: $break_xl) {
                            height: 285px;
                        }
                    }
                }
                h2 {
                    color: $featureColor4;
                    font-family: 'Dancing Script', cursive;
                    font-weight: bold;
                    font-size: 2rem;
                    line-height: 3rem;
                    margin-bottom: 10px;
                }
                .role {
                    color: $textDark;
                    font-weight: bold;
                    font-size: 1.1rem;
                }
                .about {
                    text-align: justify;
                    color: $textDark;
                    font-size: 1rem;
                    font-weight: 200;
                    line-height: 2rem;
                }
            }
        }
    }
    &.accountPage,
    &.registerPage,
    &.aboutPage,
    &.faqPage,
    &.bookPage,
    &.contactPage,
    &.pressPage,
    &.howItWorksPage {
        .hero {
            background-size: cover;
            background-position: center;
            padding: 40px 0px 60px 0px;
            .hero-content {
                .heroText {
                    font-size: 2rem;
                    color: white;
                    margin-bottom: 10px;
                }
            }
            @media (min-width: $break_xs) {
                padding: 50px 0px 70px 0px;
                .hero-content {
                    .heroText {
                        font-size: 2.5rem;
                    }
                }
            }
            @media (min-width: $break_s) {
                padding: 60px 0px 80px 0px;
                .hero-content {
                    .heroText {
                        font-size: 3rem;
                    }
                }
            }
            @media (min-width: $break_l) {
                padding: 70px 0px 90px 0px;
                .hero-content {
                    .textImg {
                        margin-bottom: 35px;
                    }
                    .heroText {
                        font-size: 3.5rem;
                    }
                }
            }
            @media (min-width: $break_xl) {
                background-position: center 30%;
                padding-bottom: 50px;
            }
            @media (min-width: $break_xxl) {
                background-position: center 20%;
                padding-bottom: 50px;
            }
            @media (min-width: $break_xxxl) {
                padding-bottom: 50px;
            }
            @media (min-width: 1700px) {
                padding-bottom: 50px;
            }
        }
        .startDate {
            text-align: center;
            label {
                font-weight: bold;
                width: initial;
                color: white;
                margin: 0px;
                display: inline-block;
                padding-right: 20px;
            }

        }
        .freeGuideSelector {
            text-align: center;
            padding: 20px 0px;
            .images {
                display: inline-block;
                width: 120px;
                margin: 0px 20px;
                img {
                    width: 100%;
                    &:nth-child(1) {
                        position: absolute;
                        left: -20px;
                        top: 20px;
                        opacity: 0.6;
                    }
                }
            }
            & > .inner {
                margin: 20px 0px;
                display: inline-block;
                color: white;
                text-align: center;
                padding: 15px 25px;
                box-shadow: 0px 4px 10px #0000000f;
                border-radius: 10px;
                background-color: #625c6d;
                span {
                    display: inline-block;
                    vertical-align: text-top;
                }
                .checkboxField {
                    display: inline-block;
                    vertical-align: text-top;
                    margin: 0px;
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                    & > .inner {
                        left: 0px;
                        top: 0px;
                    }
                }
            }
        }
    }
    &.blogPage {
        .hero {
            .hero-content {
                .textImg {
                    max-width: 200px;
                }
            }
            @media (min-width: $break_xs) {
                background-size: cover;
                background-position: center 50%;
                padding: 70px 0px 0px 0px;
                .hero-content {
                    text-align: center;
                    .textImg {
                        max-width: 300px;
                        display: inline-block;
                        vertical-align: top;
                    }
                    .logo {
                        transform: translateY(-5px);
                        margin-right: 20px;
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
            @media (min-width: $break_s) {
                padding: 90px 0px 10px 0px;
                background-position: center 60%;
                .hero-content {
                    .textImg {
                        max-width: 300px;
                    }
                    .logo {
                        width: 120px;
                        height: 120px;
                    }
                }
            }
            @media (min-width: $break_l) {
                background-position: center 50%;
                padding: 115px 0px 35px 0px;
                .hero-content {
                    .textImg {
                        max-width: 350px;
                    }
                    .logo {
                        width: 130px;
                        height: 130px;
                    }
                }
            }
            @media (min-width: $break_xl) {
                padding: 120px 0px 50px 0px;
                .hero-content {
                    .textImg {
                        max-width: 400px;
                    }
                    .logo {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
            @media (min-width: $break_xxl) {
                padding: 150px 0px 70px 0px;
            }
            @media (min-width: $break_xxxl) {
                padding: 175px 0px 95px 0px;
            }
        }
        .loading-post,
        .noPosts {
            text-align: center;
            padding-top: 50px;
            font-size: 2rem;
            color: $featureColor4;
        }
        .postList {
            padding: 50px 0px;
            .filters {
                padding-bottom: 20px;
                label {
                    color: $featureColor2;
                }
                .dropdown {
                    width: 100%;
                    background-color: white;
                    border-radius: 20px;
                    color: $textDark;
                    font-size: 1rem;
                    .selected {
                        padding: 10px 15px;
                        cursor: pointer;
                        font-weight: bold;
                    }
                    .options {
                        top: 54px;
                        border-radius: 25px;
                        .option {
                            height: 0px;
                            transition: height 0.2s ease;
                            &.open {
                                height: 41px;
                            }
                            &:hover {
                                background-color: #fff6d4;
                            }
                        }
                    }
                }
                .row > div {
                    padding-bottom: 20px;
                }
            }
            .posts {
                min-height: 50vh;
                .post-col {
                    margin: 15px 0px;
                    .post {
                        padding: 15px;
                        height: 100%;
                        border-radius: 5px;
                        background-color: #ffffff;
                        max-width: 375px;
                        margin: 0 auto;
                        &.faded {
                            opacity: 0.3;
                        }
                        .button {
                            padding: 10px 20px;
                        }
                        .inner {
                            height: 100%;
                            padding-bottom: 60px;
                            .shareWrapper {
                                position: absolute !important;
                                bottom: 0;
                                right: 0;
                                // background-color: #b0c630;
                                padding: 10px 0px;
                                border: none;
                                border-radius: 50px;
                                // width: 41px;
                                // transition: width 0.2s ease;
                                height: 42px;
                                img {
                                    margin: 0px 10px;
                                    width: 21px;
                                    cursor: pointer;
                                    display: inline-block;
                                }
                            }
                            .imgFrame {
                                width: 100%;
                                height: 200px;
                                overflow: hidden;
                                text-align: center;
                                border-radius: 3px;
                                @media (min-width: $break_xs) {
                                    height: 140px;
                                }
                                @media (min-width: $break_s) {
                                    height: 120px;
                                }
                                @media (min-width: $break_l) {
                                    height: 170px;
                                }
                                @media (min-width: $break_xl) {
                                    height: 213px;
                                }
                                img {
                                    height: 100%;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                            h2 {
                                color: $featureColor4;
                                padding-top: 15px;
                                margin-bottom: 15px;
                                font-family: 'Dancing Script', cursive;
                                font-weight: bold;
                            }
                            .created,
                            .type {
                                display: inline-block;
                                font-weight: bold;
                                color: $textDark;
                            }
                            .type {
                                color: $featureColor3;
                                margin-left: 10px;
                            }
                            .description {
                                padding-top: 15px;
                                color: $textDark;
                            }
                            .button {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .fullPost {
            padding-bottom: 50px;
            .postHeading {
                padding: 10px;
                background-color: white;
                border-radius: 5px;
                box-shadow: 0 4px 10px #0000001a;
                margin: -35px -10px 0px -10px;
                .mainTitle {
                    color: #1ac2fb;
                    font-family: 'Dancing Script', cursive;
                    font-weight: bold;
                    font-size: 3.5rem;
                    padding: 5px 20px;
                    display: block;
                    margin-bottom: 10px;
                    border-bottom: 2px dashed $backgroundLight;
                }
            }
            .created,
            .type {
                display: inline-block;
                font-weight: bold;
                color: $textDark;
            }
            .created {
                padding-left: 20px;
            }
            .type {
                color: $featureColor3;
                margin-left: 10px;
            }
            .postContent {
                min-height: 30vh;
                padding: 35px 20px 10px 20px;
                color: $textDark;
                .blog-column {
                    margin-bottom: 20px;
                    h1,h2,h3,h4,h5.h6 {
                        margin: 20px 0px;
                        color: $featureColor4;
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                    }
                    img {
                        max-width: 100%;
                        display: block;
                        margin: auto;
                        border-radius: 5px;
                        box-shadow: 0 2px 6px #00000075;
                        overflow: hidden;
                    }
                    .iframeWrapper {
                        width: 100%;
                        height: 250px;
                        border-radius: 5px;
                        box-shadow: 0 2px 6px #00000075;
                        overflow: hidden;
                        @media (min-width: $break_xs) {
                            height: 300px;
                        }
                        @media (min-width: $break_s) {
                            height: 350px;
                        }
                        iframe {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            .social-media {
                text-align: center;
                img {
                    display: inline-block;
                    cursor: pointer;
                    width: 30px;
                    margin: 0px 15px;
                }
            }
            .bottom-controls {
                text-align: center;
                padding-top: 20px;
                margin-top: 20px;
                border-top: 2px dashed #dedede;
            }
        }
    }
    &.aboutPage,
    &.faqPage,
    &.bookPage,
    &.pressPage,
    &.howItWorksPage {
        .hero {
            background-position: center;
            padding: 60px 0px 40px 0px;
            .textImg {
                width: 70%;
            }
            @media (min-width: $break_xs) {
                padding-top: 75px;
                .textImg {
                    width: 60%;
                }
            }
            @media (min-width: $break_s) {
                padding: 105px 0px 45px 0px;
                .textImg {
                    width: 60%;
                }
            }
            @media (min-width: $break_l) {
                padding: 130px 0px 60px 0px;
            }
            @media (min-width: $break_xl) {
                background-position: center 45%;
                padding: 150px 0px 70px 0px;
            }
            @media (min-width: $break_xxl) {

                padding: 180px 0px 90px 0px;
            }
            @media (min-width: $break_xxxl) {
                padding: 210px 0px 110px 0px;
            }
        }
    }
    &.faqPage {
        .hero {
            @media (min-width: $break_xs) {
                padding: 70px 0px 10px 0px;
            }
            @media (min-width: $break_s) {
                padding: 90px 0px 10px 0px;
            }
            @media (min-width: $break_l) {
                padding: 100px 0px 20px 0px;
            }
            @media (min-width: $break_xl) {
                padding: 130px 0px 20px 0px;
            }
            @media (min-width: $break_xxl) {
                padding: 150px 0px 50px 0px;
            }
            @media (min-width: $break_xxxl) {
                padding: 175px 0px 75px 0px;
            }
        }
    }
    &.bookPage {
        text-align: justify;
        .tab-selector {
            top: -41px;
            .tab {
                padding: 10px 15px;
                font-size: 1rem;
                &:first-child {
                    margin-right: 10px;
                }
            }
            @media (min-width: $break_l) {
                top: -45px;
                .tab {
                    font-size: 1.2rem;
                }
            }
        }
        .bookImg {
            margin-top: 30px;
            @media (min-width: $break_s) {
                margin-top: 0px;
            }
            img {
                width: 100%;
            }
        }
        .aboutSection {
            padding: 0;
        }
        .imageFrame {
            margin-bottom: 15px;
            @media (min-width: $break_s) {
                &.floatedRight {
                    float: right;
                    margin: 0px 0px 20px 20px;
                }
                &.medium {
                    width: 40%;
                }
            }
        }
        .quoteSection {
            font-style: italic;
            padding: 25px 0px;
            @media (min-width: $break_xl) {
                font-size: 1.2rem;
            }
            &.addPadding {
                padding-top: 30px;
                font-weight: bold;
            }
            .text {
                color: $textDark;
            }
            .author {
                padding-top: 20px;
                font-weight: bold;
                text-align: right;
                color: $featureColor2;
            }
        }
        .title-col {
            text-align: center;
            margin: 30px 0px;
            padding: 30px 20px;
            border-top: 2px dashed #d7d7d7;
            border-bottom: 2px dashed #d7d7d7;
            h1 {
                color: $featureColor4;
                padding-bottom: 20px;
            }
            h5 {
                padding-bottom: 20px;
            }
        }
        .button {
            margin: auto;
        }
        .referenceSection {
            .reference {
                color: $textDark;
                padding-bottom: 10px;
                .nameDate {
                    font-weight: bold;
                    padding-right: 5px;
                }
                .sourceDescription {
                    padding-left: 5px;
                    border-left: 1px solid #89898a;
                }
                a {
                    padding-left: 10px;
                    font-weight: bold;
                    color: $featureColor3;
                }

            }
        }
    }
    &.faqPage {
        min-height: 100vh;
        text-align: center;
        .faqSection {
            .filter {
                text-align: left;
                margin-bottom: 30px;
                label {
                    color: $featureColor2;
                    font-size: 1.4rem;
                }
                input {
                    border: 2px solid $featureColor2;
                }
            }
            .question {
                text-align: left;
                margin-bottom: 40px;
                padding-bottom: 30px;
                border-bottom: 2px dashed #d6d6d6;
                .sectionTitle {
                    margin-bottom: 40px;
                }
                iframe {
                    display: block;
                    width: 100%;
                    margin: 10px 0px;
                    height: 300px;
                }
                .answer {
                    width: 100%;
                    .sectionText {
                        margin-bottom: 15px;
                    }
                }
                @media (min-width: $break_l) {
                    iframe {
                        display: inline-block;
                        vertical-align: top;
                        width: 48%;
                        margin: 0 0 0 2%;
                        height: 250px;
                    }
                    .answer {
                        &.withVid {
                            display: inline-block;
                            vertical-align: top;
                            width: 48%;
                            margin-right: 2%;
                        }
                    }
                }
                @media (min-width: $break_xl) {
                    iframe {
                        height: 300px;
                    }
                }
            }
        }
    }
    &.accountPage {
        .calendar {
            background-color: $backgroundLight;
            @media print {
                background-color: #fff;
            }
            .popup {
                position: absolute;
                padding: 10px;
                text-align: center;
                max-width: 100%;
                width: 800px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                @media (min-width: $break_xs) {
                    text-align: left;
                }
                .inner {
                    background-color: white;
                    padding: 20px;
                    border-radius: 10px;
                    .popupTitle {
                        color: $featureColor4;
                        font-family: 'Dancing Script', cursive;
                        font-size: 3rem;
                        margin-bottom: 0px;
                        font-weight: bold;
                    }
                    .popupText {
                        font-size: 1.1rem;
                        margin-bottom: 20px;
                        color: $textLight;
                        @media (min-width: $break_l) {
                            font-size: 1.2rem;
                        }
                    }
                    .out,
                    .in {
                        display: flex;
                        flex-direction: column;
                        @media (min-width: $break_s) {
                            flex-direction: row;
                        }
                        .item {
                            flex: 1;
                            text-align: center;
                            padding: 5px;
                            img {
                                width: 60px;
                                height: 60px;
                                margin: 0 auto;
                            }
                            .text {
                                margin-top: 10px;
                                font-weight: bold;
                                color: $textDark;
                                font-size: 0.9rem;
                            }
                        }
                        @media (min-width: $break_l) {
                            .item {
                                img {
                                    width: 70px;
                                    height: 70px;
                                }
                                .text {
                                    font-weight: 1rem;
                                }
                            }
                        }
                    }
                }
            }
            .tab-selector {
                top: -51px;
                .tab {
                    width: 19%;
                    height: 51px;
                    margin: 0 0.5%;
                    span {
                        display: block;
                        &.number {
                            font-weight: bold;
                            font-size: 1.1rem;
                        }
                    }
                    &.selected {
                        background-color: $backgroundLight;
                        color: $heroText;
                    }
                    &.desktopWeekTab {
                        display: none;
                    }
                    &.beforeStart,
                    &.recipesToPrep,
                    &.andBeyond,
                    &.mobileWeekTab  {
                        line-height: 1rem;
                    }

                    &.shoppingListTab {
                        background-color: $featureColor2;
                        padding: 13px 0px;
                        span {
                            display: none;
                        }
                        img {
                            height: 25px;
                            width: 25px;
                        }
                        &.selected {
                            background-color: $featureColor3;
                            color: white;
                        }
                    }
                }
                @media (min-width :$break_xs) {
                    .tab {
                        font-size: 1rem;
                        span {
                            &.number {
                                font-size: 1.2rem;
                            }
                        }
                        &.beforeStart,
                        &.recipesToPrep,
                        &.andBeyond,
                        &.mobileWeekTab {
                            line-height: 1.1rem;
                            padding-bottom: 11px;
                        }
                        &.shoppingListTab {
                            background-color: $featureColor2;
                            padding: 15.5px 0px;
                        }
                    }
                }
                @media (min-width :$break_s) {
                    top: -45px;
                    .tab {
                        height: 45px;
                        font-size: 1.2rem;
                        width: 16.5%;
                        span {
                            display: inline-block;
                            &.number {
                                margin-left: 5px;
                                font-weight: 400;
                            }
                        }
                        &.shoppingListTab {
                            font-size: 1rem;
                            padding: 12px 0px;
                            span {
                                display: inline-block;
                            }
                            img {
                                display: inline-block;
                                vertical-align: text-top;
                                margin-left: 5px;
                                width: 17px;
                                height: 17px;
                            }
                        }
                        &.beforeStart,
                        &.recipesToPrep,
                        &.andBeyond {
                            font-size: 0.9rem;
                            padding: 10px 0px;
                            line-height: 1.8rem;
                            span {
                                margin-right: 4px;
                                display: inline-block;
                                &:last-child {
                                    margin-right: 0px;
                                }
                            }
                        }
                        &.mobileWeekTab {
                            display: none;
                        }
                        &.desktopWeekTab {
                            display: inline-block;
                            font-size: 0.9rem;
                            width: 29%;
                            cursor: initial;
                            span {
                                margin-right: 10px;
                            }
                            .week {
                                display: inline-block;
                                background-color: #e8e7e2;
                                color: #36434c;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                text-align: center;
                                margin: 0px 2px;
                                margin-top: -2px;
                                transition: all 0.2s ease;
                                &.selected {
                                    background-color: $featureColor3;
                                    color: white;
                                }
                                span {
                                    margin: 0;
                                    margin-top: 2px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
                @media (min-width :$break_l) {
                    .tab {
                        font-size: 1.4rem;
                        &.shoppingListTab {
                            font-size: 1.3rem;
                            padding: 11px 0px;
                            img {
                                width: 21px;
                                height: 21px;
                            }
                        }
                        &.beforeStart,
                        &.recipesToPrep,
                        &.andBeyond {
                            line-height: 2.1rem;
                            font-size: 1.3rem;
                        }
                        &.desktopWeekTab {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
            .tab-view {
                padding: 30px 0px 40px 0px;
                &.bys {
                    padding: 0px;
                    margin-top: -30px;
                }
                .week-view {
                    .heading {
                        margin-bottom: 40px;
                        .left {
                            text-align: center;
                            h1 {
                                display: none;
                            }
                            img {
                                display: none;
                            }
                        }
                        .mobileWeekChange {
                            color: $textDark;
                            font-weight: bold;
                            font-size: 1.4rem;
                            margin-bottom: 20px;
                            .weekText {
                                margin-right: 5px;
                            }
                            .week {
                                color: $textDark;
                                display: inline-block;
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                background-color: #e8e7e2;
                                text-decoration: none;
                                margin: 0px 4px;
                                .number {
                                    display: block;
                                    margin-top: 5px;
                                }
                                &.selected {
                                    background-color: $featureColor3;
                                    color: white;
                                }
                            }
                            @media (min-width: $break_xs) {
                                font-size: 1.6;
                                .week {
                                    width: 50px;
                                    height: 50px;
                                    margin: 0px 8px;
                                    .number {
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                        .right {
                            text-align: center;
                            .rubric {
                                display: none;
                                margin: 0px;
                            }
                            .sub-rubric {
                                text-align: justify;
                                padding: 20px 0px;
                                line-height: 30px;
                                color: #7b868c;
                            }
                            .button {
                                padding: 10px;
                                transition: background-color 0.2s ease;
                                @media (min-width: $break_xs) {
                                    padding: 10px 20px;
                                }
                                &.in {
                                    border-radius: 50px 0px 0px 50px;
                                }
                                &.out {
                                    border-radius: 0px 50px 50px 0px;
                                    background-color: #9fb32e;
                                }
                                &.in,
                                &.out {
                                    &.active {
                                        background-color: #665E77;
                                        &:hover {
                                            background-color: #665E77;
                                        }
                                    }
                                }
                                &:hover {
                                    background-color: #748320;
                                }
                                img {
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 5px;
                                }
                            }
                        }
                        @media (min-width: $break_s) {
                            .left {
                                display: inline-block;
                                vertical-align: top;
                                text-align: left;
                                width: 20%;
                                h1 {
                                    font-family: "Tahu", sans-serif;
                                    color: $textLight;
                                    display: block;
                                    margin: 0px 20px 0px 0px;
                                    font-size: 3.5rem;
                                }
                                img {
                                    display: block;
                                    width: 60px;
                                    height: 60px;
                                }
                                .mobileWeekChange {
                                    display: none;
                                }
                            }
                            .right {
                                display: inline-block;
                                vertical-align: top;
                                text-align: left;
                                width: 80%;
                                .rubric {
                                    display: block;
                                    color: $featureColor4;
                                    font-family: 'Roboto', sans-serif;
                                    font-weight: 200;
                                    font-size: 1.8rem;
                                    margin-bottom: 20px;
                                }
                                .button {
                                    &.in {
                                        margin-right: 20px;
                                    }
                                    &.in,
                                    &.out {
                                        background-color: $featureColor2;
                                        border-radius: 50px;
                                        &:hover {
                                            background-color: #9fb32e;
                                        }
                                    }
                                }
                            }
                        }
                        @media (min-width: $break_l) {
                            .left {
                                h1 {
                                    font-size: 5rem;
                                    margin-left: 8px;
                                    margin-bottom: -10px;
                                }
                                img {
                                    width: 120px;
                                    height: 120px;
                                }
                            }
                            .right {
                                .rubric{
                                    font-size: 2.7rem;
                                    margin: 0px;
                                }
                                .button {
                                    padding: 15px 20px;
                                }
                            }
                        }
                    }
                    .carousel {
                        &.mealPlan {
                            .outer {
                                .items {
                                    .dayPlan {
                                        display: inline-block;
                                        vertical-align: middle;
                                        width: 100%;
                                        @media (min-width: $break_s) {
                                            padding: 0px 20px;
                                            width: 50%;
                                        }
                                        @media (min-width: $break_l) {
                                            width: 33.33333333333%;
                                        }
                                        .inner {
                                            background-color: white;
                                            border-radius: 5px;
                                            width: 100%;
                                            max-width: 330px;
                                            margin: 0 auto;
                                            padding-bottom: 30px;
                                            .planHeading {
                                                padding: 0px 20px 25px 20px;
                                                .dayNumber {
                                                    display: inline-block;
                                                    vertical-align: middle;
                                                    background-color: $featureColor4;
                                                    padding: 5px 10px;
                                                    span {
                                                        text-align: center;
                                                        color: white;
                                                        display: block;
                                                        &:last-child {
                                                            font-weight: bold;
                                                            font-size: 1.3rem;
                                                        }
                                                    }
                                                }
                                                .dayName {
                                                    display: inline-block;
                                                    vertical-align: middle;
                                                    font-family: 'Dancing Script', cursive;
                                                    font-weight: bold;
                                                    color: $textLight;
                                                    font-size: 2.2rem;
                                                    margin-left: 20px;
                                                }
                                                .date {
                                                    position: absolute;
                                                    top: 5px;
                                                    right: 20px;
                                                    span {
                                                        display: block;
                                                        text-align: center;
                                                        color: $heroText;
                                                        font-weight: bold;
                                                        font-size: 1.4rem;
                                                        &:last-child {
                                                            color: $featureColor3;
                                                            font-size: 1.1rem;
                                                            margin-top: -5px;
                                                        }
                                                    }
                                                }
                                            }
                                            .meals {
                                                margin: 0px 20px;
                                                .swap-symbol {
                                                    opacity: 0.5;
                                                    margin-bottom: 10px;
                                                    .dash {
                                                        border-bottom: 2px dashed #d5d5d5;
                                                    }
                                                    .text {
                                                        color: #7b868c;
                                                        font-weight: bold;
                                                        text-align: center;
                                                        padding: 5px;
                                                        line-height: 30px;
                                                        img {
                                                            margin-right: 12px;
                                                            width: 30px;
                                                            height: 30px;
                                                        }
                                                    }
                                                }
                                                .meal {
                                                    margin-bottom: 30px;
                                                    cursor: pointer;
                                                    display: block;
                                                    &:last-child {
                                                        margin-bottom: 0px;
                                                    }
                                                    &:nth-child(2) {
                                                        margin-bottom: 10px;
                                                    }
                                                    div.mealImage {
                                                        display: inline-block;
                                                        border-radius: 2px;
                                                        box-shadow: 0px 2px 2px #0000005e;
                                                        vertical-align: middle;
                                                        width: 50%;
                                                        height: 100px;
                                                        background-size: cover;
                                                        background-position: center;
                                                    }
                                                    .info {
                                                        display: inline-block;
                                                        vertical-align: middle;
                                                        padding-left: 10px;
                                                        width: 50%;
                                                        h2 {
                                                            font-size: 1.5rem;
                                                            font-weight: bold;
                                                            color: $featureColor4;
                                                        }
                                                        p {
                                                            margin-bottom: 0px;
                                                            color: $textDark;
                                                            font-weight: bold;
                                                            white-space: pre-wrap;
                                                            font-size: 0.9rem;
                                                        }
                                                    }
                                                }
                                                &.meals-locked {
                                                    height: 340px;
                                                    .button {
                                                        margin: auto;
                                                        display: block;
                                                        padding: 13px 30px;
                                                    }
                                                    .padlock {
                                                        background-color: #f4f4f4;
                                                        padding: 30px;
                                                        height: 140px;
                                                        width: 140px;
                                                        border-radius: 140px;
                                                        margin: 40px auto;
                                                        img {
                                                            width: 100%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .under-carousel {
                        padding: 30px 20px;
                        text-align: center;
                        border-top: 2px dashed #e1e1e1;
                        margin-top: 40px;
                        color: #7b868c;
                        font-size: 1.1rem;
                    }
                }
                .day-view {
                    .dayChanger {
                        margin-bottom: 20px;
                        background-color: white;
                        border-radius: 10px;
                        overflow: hidden;
                        @media print {
                            display: none;
                        }
                        .chevron {
                            background-color: #f8f8f8;
                            display: inline-block;
                            vertical-align: middle;
                            opacity: 0.7;
                            transition: opacity 0.2s ease;
                            width: 20%;
                            text-align: center;
                            padding: 11px 0px;
                            height: 52px;
                            &:hover {
                                opacity: 1;
                            }
                            img {
                                cursor: pointer;
                                width: 30px;
                                height: 30px;
                            }
                        }
                        .inner {
                            width: 60%;
                            display: inline-block;
                            vertical-align: middle;
                            .dayNumber {
                                background-color: $featureColor4;
                                color: white;
                                display: inline-block;
                                vertical-align: middle;
                                text-align: center;
                                padding: 5px;
                                span {
                                    display: block;
                                    &:last-child {
                                        font-weight: bold;
                                    }
                                }
                            }
                            .dayName {
                                display: inline-block;
                                vertical-align: middle;
                                font-family: 'Dancing Script', cursive;
                                font-weight: bold;
                                color: $textLight;
                                font-size: 1.7rem;
                                margin-left: 7px;
                            }
                            .date {
                                position: absolute;
                                top: 5px;
                                right: 10px;
                                span {
                                    display: block;
                                    text-align: center;
                                    color: $heroText;
                                    font-weight: bold;
                                    font-size: 1.2rem;
                                    &:last-child {
                                        color: $featureColor3;
                                        font-size: 0.9rem;
                                        margin-top: -5px;
                                    }
                                }
                            }
                        }
                        @media (min-width: $break_xs) {
                            .inner {
                                .dayName {
                                    font-size: 2.5rem;
                                    margin-left: 15px;
                                }
                                .date {
                                    top: 1px;
                                    span {
                                        font-size: 1.4rem;
                                        &:last-child {
                                            font-size: 1.1rem;
                                        }
                                    }
                                }
                            }
                        }
                        @media (min-width: $break_s) {
                            overflow: initial;
                            box-shadow: none;
                            background-color: transparent;
                            .chevron {
                                background-color: transparent;
                                width: 10%;
                                height: initial;
                                img {
                                    width: 40px;
                                    height: 40px;
                                }
                                &.left {
                                    text-align: left;
                                }
                                &.right {
                                    text-align: right;
                                }
                            }
                            .inner {
                                width: 80%;
                                box-shadow: none;
                                .dayNumber {
                                    border-radius: 5px;
                                    padding: 7px;
                                    span {
                                        &:last-child {
                                            font-size: 1.5rem;
                                            margin-top: -5px;
                                        }
                                    }
                                }
                                .dayName {
                                    font-size: 4rem;
                                    margin-left: 30px;
                                }
                                .date {
                                    top: 6px;
                                    span {
                                        font-size: 2rem;
                                        &:last-child {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }
                        }
                        @media (min-width: $break_l) {
                            display: inline-block;
                            vertical-align: middle;
                            width: 80%;
                        }
                    }
                    .lockedDay {
                        text-align: center;
                        padding: 30px 20px;
                        background-color: white;
                        border-radius: 10px;
                        margin-bottom: 60px;
                        h3 {
                            color: #1ac2fb;
                            font-size: 2rem;
                            font-weight: 200;
                            margin-bottom: 20px;
                        }
                        h5 {
                            color: #7b868c;
                            font-weight: 200;
                            font-size: 1.2rem;
                        }
                        .button {
                            margin: auto;
                            display: block;
                            padding: 13px 30px;
                        }
                        .padlock {
                            background-color: #f4f4f4;
                            padding: 30px;
                            height: 140px;
                            width: 140px;
                            border-radius: 140px;
                            margin: 40px auto;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .goBack {
                        margin-bottom: 20px;
                        text-align: center;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                            transform: rotate(180deg);
                            margin-left: 10px;
                            width: 20px;
                            height: 20px;
                        }
                        @media (min-width: $break_l) {
                            display: inline-block;
                            vertical-align: middle;
                            width: 16%;
                            margin-left: 4%;
                        }
                        @media print {
                            display: none;
                        }
                    }
                    .recipes {
                        border-radius: 10px;
                        // overflow: hidden;
                        .recipe {
                            // overflow: hidden;
                            .recipeType {
                                background-color: $backgroundLight;
                                transition: background-color 0.2s ease;
                                cursor: pointer;
                                padding: 15px;
                                border-bottom: 1px solid #dedede;
                                img {
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 20px;
                                    transition: transform 0.2s ease, border-bottom-color 0.2s ease;
                                }
                                h1 {
                                    color: $featureColor4;
                                    font-weight: bold;
                                    display: inline-block;
                                    vertical-align: middle;
                                    font-size: 2rem;
                                    margin: 0;
                                    @media (min-width: $break_xs) {
                                        font-size: 2.5rem;
                                    }
                                }
                                @media print {
                                    background-color: #fff;
                                }
                            }
                            .recipe-container {
                                display: none;
                            }
                            &.expanded .recipeType {
                                background-color: white;
                                border-bottom-color: white;
                                img {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
                .shoppingListView {
                    .title {
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                        color: $textLight;
                        font-size: 3.5rem;
                        margin-bottom: 25px;
                        @media (min-width: $break_xs) {
                            font-size: 4.5rem;
                        }
                        @media print {
                            display: none;
                        }
                    }
                    .beforeStatement {
                        padding-bottom: 15px;
                        color: $textDark;
                        h2 {
                            color: $featureColor2;
                            font-weight: bold;
                            font-size: 1.3rem;
                            margin: 0 0 20px 0;
                            @media print {
                                display: none;
                            }
                        }
                    }
                    .storeCupboard-info {
                        padding: 20px 40px;
                        text-align: justify;
                        height: 164px;
                        /* border: 1px solid grey; */
                        /* border-right: 1px dashed #dedede; */
                        border-bottom: 1px dashed #dedede;
                    }
                    .fromTo {
                        .dayDrop {
                            margin: 0 5px;
                            .currentValue {
                                padding: 10px 9px;
                            }
                        }
                        .toWrapper {
                            margin-top: 10px;
                        }
                        @media (min-width: 425px) {
                            .fromWrapper,
                            .toWrapper {
                                margin: 0;
                                display: inline-block;
                            }
                        }
                        @media (min-width: $break_xs) {

                            span {
                                font-size: 1.3rem;
                            }
                            .dayDrop {
                                margin: 0px 15px;
                                .currentValue {
                                    padding: 10px 15px;
                                }
                            }
                        }
                        @media (min-width: $break_s) {
                            margin-bottom: 20px;
                        }
                        @media print {
                            display: none;
                        }
                    }
                    .mobileIcons {
                        margin: 20px 0px;
                        .downloadBtn {
                            margin-right: 20px;
                        }
                        @media (min-width: $break_xs) {
                            margin: 30px 0px;
                            .iconBtn {
                                width: 40px;
                                height: 40px;
                                &.downloadBtn {
                                    margin-right: 30px;
                                }
                            }
                        }
                        @media (min-width: $break_s) {
                            display: none;
                        }
                        @media print {
                            display: none;
                        }
                    }
                    .list {
                        background-color: white;
                        border-radius: 10px;
                        padding-bottom: 40px;
                        .desktopIcons {
                            display: none;
                            @media (min-width: $break_s) {
                                z-index: 4;
                                display: block;
                                position: absolute;
                                right: 20px;
                                top: 37.5px;
                                .downloadBtn {
                                    margin-right: 15px;
                                }
                            }
                            @media print {
                                display: none;
                            }
                        }
                        .rangeTitle {
                            color: $featureColor4;
                            font-family: 'Dancing Script', cursive;
                            font-weight: bold;
                            font-size: 2rem;
                            padding: 10px;
                            border-bottom: 1px dashed #dedede;
                            @media (min-width: $break_xs) {
                                font-size: 3rem;
                                padding: 10px 20px;
                            }
                            @media (min-width: $break_s) {
                                font-size: 3rem;
                            }
                        }
                        .outside {
                            margin: 0;
                            font-family: 'Roboto', sans-serif;
                            color: #b3b3b3;
                            font-size: 1.5rem;
                            text-align: center;
                            padding: 20px;
                        }
                        .ingredients {
                            @media only print {
                                display: block;
                            }
                            h1 {
                                padding-left: 30px;
                                line-height: 82px;
                                font-family: 'Dancing Script', cursive;
                                font-weight: bold;
                                color: $featureColor2;
                                font-size: 2.3rem;
                                margin: 0;
                            }
                            h2 {
                                font-size: 1.5rem;
                                line-height: 41px;
                                color: $featureColor3;
                                padding-left: 30px;
                            }
                            .items {
                                column-count: 1;
                                column-gap: 0;
                                .ingredient {
                                    line-height: 41px;
                                    padding-left: 50px;
                                    color: $textDark;
                                    .borderDiv {
                                        height: 0px;
                                        border-bottom: 1px dashed #dedede;
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                        width: 100%;
                                    }
                                    img {
                                        position: absolute;
                                        top: 50%;
                                        left: 10px;
                                        width: 30px;
                                        height: 30px;
                                        transform: translateY(-50%);
                                    }
                                    span {
                                        display: inline-block;
                                    }
                                }
                                @media (min-width: $break_xs) {
                                    h1 {
                                        font-size: 2.5rem;
                                        padding: 10px 20px;
                                    }
                                    .ingredient {
                                        padding-left: 70px;
                                        img {
                                            left: 20px;
                                            width: 35px;
                                            height: 35px;
                                        }
                                        span {
                                            font-size: 1rem;
                                        }
                                    }
                                }
                                @media (min-width: $break_s) {
                                    column-count: 2;
                                    h1 {
                                        font-size: 2.5rem;
                                    }
                                }
                                @media (min-width: $break_l) {
                                    column-count: 3;
                                }
                                @media (min-width: $break_xl) {
                                    column-count: 4;
                                }
                                @media only print {
                                    column-count: 4;
                                }
                                &.storeCupboard-items {
                                    column-count: 1;
                                    @media (min-width: $break_l) {
                                        column-count: 2;
                                    }
                                }
                            }
                        }
                    }
                    .essentialRecipeSwitches {
                        .checkboxField {
                            display: inline-block;
                        }
                    }
                    .snackRecipeSwitches {
                        .numberField-new {
                            display: inline-block;
                            vertical-align: middle;
                            width: 100%;
                            @media (min-width: $break_s) {
                                width: 50%;
                            }
                            @media (min-width: $break_l) {
                                width: 33%;
                            }
                        }
                    }
                }
                .beyondView {
                    .first-row {
                        margin-bottom: 20px;
                    }
                    h1 {
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                        color: $featureColor4;
                        font-size: 3.5rem;
                        margin-bottom: 30px;
                        @media (min-width: $break_xs) {
                            font-size: 5rem;
                        }
                    }
                    .imageFrame {
                        margin-bottom: 15px;
                        @media (min-width: $break_s) {
                            &.floatedLeft {
                                float: left;
                                margin: 0px 20px 20px 0px;
                            }
                            &.floatedRight {
                                float: right;
                                margin: 0px 0px 20px 20px;
                            }
                            &.small {
                                width: 40%;
                            }
                            &.medium {
                                width: 50%;
                            }
                        }
                    }
                }
                .recipesToPrepView {
                    min-height: 80vh;
                    .heading {
                        line-height: 30px;
                        text-align: justify;
                        font-size: 1.3rem;
                        padding-bottom: 30px;
                        margin-bottom: 30px;
                        color: $featureColor4;
                        border-bottom: 1px dashed #c1c1c1;
                        a {
                            color: $featureColor2;
                            text-decoration: none;
                        }
                    }
                }
                .beforeView {
                    color: $textDark;
                    hr {
                        padding: 20px 0px;
                        border-top: 2px dashed #e6e5e2;
                    }
                    h1 {
                        font-weight: 200;
                        color: $featureColor4;
                        margin-bottom: 20px;
                        font-size: 1.5rem;
                        @media (min-width: $break_s) {
                            font-size: 2rem;
                        }
                    }
                    h3 {
                        margin-bottom: 15px;
                    }
                    h5 {
                        margin-bottom: 20px;
                    }
                    .textPdf-col {
                        .no-margin {
                            margin-bottom: 0px;
                        }
                        .link {
                            margin: 20px auto;
                        }
                    }
                    .pushBack {
                        text-align: center;
                        margin-bottom: 20px;
                        background-color: #eae9e4;
                        padding: 10px 20px;
                        border-radius: 15px;
                        .daySelector {
                            .dayPickerWrapper {
                                display: block;
                                margin-top: 10px;
                                .showCalendar {
                                    padding-left: 40px;
                                    .calendarIcon {
                                        width: 20px;
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        left: 15px;
                                    }
                                }
                                .DayPicker {
                                    position: absolute;
                                    top: 45px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    z-index: 2;
                                    background-color: #b0c630;
                                    border-radius: 20px;
                                    box-shadow: 0px 4px 5px #00000045;
                                    color: white;
                                    .DayPicker-wrapper {
                                        .DayPicker-Months {
                                            .DayPicker-Month {
                                                .DayPicker-WeekdaysRow {
                                                    .DayPicker-Weekday {
                                                        color: white;
                                                    }
                                                }
                                                .DayPicker-Body {
                                                    .DayPicker-Week {
                                                        .DayPicker-Day {
                                                            &.DayPicker-Day--selected {
                                                                background-color: $featureColor3;
                                                            }
                                                            &.DayPicker-Day--today {
                                                                color: #3faace;
                                                            }
                                                            &:hover {
                                                                background-color: #cade52;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            span {
                                display: inline-block;
                                vertical-align: middle;
                                color: $textDark;
                                font-size: 1.1rem;
                            }
                            .dropdowns {
                                margin-top: 10px;
                                .field {
                                    &.dropdownField {
                                        margin-bottom: 0px !important;
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin: 0px 8px;
                                    }
                                }
                            }
                        }
                        .newDate {
                            margin-top: 10px;
                            .date {
                                color: $featureColor4;
                                font-weight: bold;
                            }
                            .buttons {
                                margin-top: 5px;
                                .button {
                                    margin: 0px 5px;
                                }
                                .cancel {
                                    background-color: #a31818;
                                    margin-top: 10px;
                                }
                            }
                        }
                        @media (min-width: $break_xs) {
                            .daySelector {
                                .dayPickerWrapper {
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-left: 10px;
                                    margin-top: 0px;
                                    .DayPicker {
                                        left: 0;
                                        transform: translateX(0);
                                    }
                                }
                                span {
                                    font-size: 1.2rem;
                                }
                                .dropdowns {
                                    margin-top: 0px;
                                    display: inline-block;
                                    vertical-align: middle;
                                }
                            }
                            .newDate {
                                .buttons {
                                    margin-top: 15px;
                                    .button {
                                        margin: 0px;
                                        &.cancel {
                                            margin-top: 0px;
                                            margin-left: 10px;
                                        }
                                    }

                                }
                            }
                        }
                    }
                    .tiles-row {
                        .tile-col {
                            margin-bottom: 30px;
                            .pageTile {
                                padding: 20px;
                                padding-bottom: 61px;
                                border-radius: 15px;
                                color: white;
                                max-width: 350px;
                                margin: 0 auto;
                                overflow: hidden;
                                min-height: 300px;
                                h1 {
                                    font-family: 'Montserrat', sans-serif;
                                    font-weight: 800;
                                    color: white;
                                    font-size: 2.7rem;
                                    line-height: 3rem;
                                    margin-bottom: 7px;
                                }
                                h2 {
                                    font-weight: 300;
                                    span {
                                        font-weight: 700;
                                    }
                                }
                                .button {
                                    position: absolute;
                                    bottom: 20px;
                                    left: 20px;
                                    padding: 10px 18px;
                                }
                                .tileLink {
                                    cursor: pointer;
                                    display: block;
                                    padding: 22px 20px;
                                    padding-left: 55px;
                                    color: white;
                                    text-decoration: none;
                                    font-weight: bold;
                                    font-size: 1.3rem;
                                    background: linear-gradient(45deg, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                                    span {
                                        line-height: 1.5rem;
                                        display: inline-block;
                                    }
                                    img {
                                        position: absolute;
                                        top: 50%;
                                        left: 20px;
                                        width: 25px;
                                        transform: translateY(-50%);
                                    }
                                    &:last-child {
                                        border-radius: 0px 0px 15px 15px;
                                    }
                                }
                                &.orange {
                                    background: linear-gradient(to bottom, rgba(252,179,22,1) 0%,rgba(230,117,0,1) 100%);
                                }
                                &.bgImg {
                                    background-position: center;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    height: 100%;
                                }
                                &.noPadding {
                                    padding: 0;
                                }
                            }

                        }
                    }
                    .otherInfo {
                        background-position: right;
                        background-repeat: no-repeat;
                        background-size: cover;
                        padding: 50px 0px;
                        .otherLinks {
                            .otherLink {
                                display: block;
                                margin-bottom: 15px;
                                .imgWrap {
                                    background: linear-gradient(45deg, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                                    border-radius: 50%;
                                    width: 35px;
                                    height: 35px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    padding: 8px;
                                    text-decoration: none;
                                    img {
                                        width: 100%;
                                        margin-bottom: 2px;
                                    }
                                }
                                span {
                                    color: $textDark;
                                    font-weight: bold;
                                    font-size: 1.1rem;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
                .questionnaire {
                    color: $textDark;
                    h1, h2 {
                        color: $featureColor4;
                        font-weight: 100;
                    }
                    label {
                        color: #596268;
                    }
                    .dropdown {
                        width: 100%;
                    }
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: #f5f4f0a6;
                        z-index: 99;
                    }
                    .question-section-header {
                        margin: 30px 0px;
                        padding: 10px 20px;
                        background-color: #eae9e4;
                        border-radius: 50px;
                        h2 {
                            margin: 0px;
                            font-size: 1.8rem;
                            span {
                                margin-right: 15px;
                                font-weight: bold;
                                color: $textDark;
                            }
                        }
                    }
                    .textSection {
                        padding-top: 20px;
                    }
                    .question, .result {
                        padding: 0px;
                        label {
                            color: $textDark;
                        }
                    }
                    .result {
                        padding: 10px;
                        .inner {
                            padding: 20px;
                            background-color: white;
                            border-radius: 4px;
                            box-shadow: 0px 1px 7px #00000024;
                            label {
                                margin-bottom: 30px;
                                margin-left: 0px;
                                font-size: 1.2rem;
                                height: 80px;
                            }
                        }
                    }
                    .notFinished {
                        font-weight: bold;
                        font-size: 1.2rem;
                        color: #e48a8a;
                        text-align: center;
                        padding: 10px;
                        background-color: #f6e6e2;
                    }
                }
            }
        }
        .recipeContent {
            background-color: #fff;
            padding: 10px 20px 40px 20px;
            @media print {
                margin-top: 40px !important;
            }
            .blogletHandle {
                & > span {
                    cursor: pointer;
                    color: $featureColor4 !important;
                }
                &:after {
                    content: "?";
                    position: absolute;
                    left: 13px;
                    background-color: $featureColor4;
                    line-height: 20px;
                    width: 20px;
                    color: white;
                    font-size: 1.2rem;
                    font-weight: bold;
                    top: 16px;
                    text-align: center;
                    display: block;
                    border-radius: 20px;
                    box-shadow: 0px 2px 5px #00000059;
                }
            }
            .bloglet {
                position: absolute;
                max-width: 90vh;
                padding: 15px 15px 25px 15px;
                line-height: 28px;
                // left: 50%;
                // transform: translateX(-50%);
                text-align: justify;
                background-color: white;
                border-radius: 5px;
                box-shadow: 0px 5px 15px #5962688f;
                border: 2px dotted $featureColor2;
                z-index: 9999;
                transform: scale(0);
                transition: transform 0.2s ease;
                left: 0px;
                &.active {
                    transform: scale(1);
                }
                @media (min-width: 350px) {
                    width: 350px;
                    left: initial;
                }
                .blogletType {
                    text-align: right;
                    position: absolute;
                    bottom: 0px;
                    right: 7px;
                    font-size: 0.9rem;
                    color: #b0c630;
                }
            }
            .mealImage {
                display: block;
                width: 100%;
                height: 260px;
                background-position: center;
                background-size: cover;
                border-radius: 3px;
                box-shadow: 0px 2px 2px #0000005e;
            }
            .ingredientsList {
                a.ingredient {
                    color: #b0c630;
                    span {
                        font-weight: bold;
                        color: #b0c630;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .ingredient {
                    display: block;
                    padding: 4.5px 0px 4.5px 35px;
                    margin-bottom: 15px;
                    .ingDescription {
                        color: $textLight;
                        padding-right: 5px;
                    }
                    img {
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    span {
                        font-weight: bold;
                        color: $textDark;
                    }
                }
            }
            .recipeName {
                color: $featureColor4;
                font-family: 'Dancing Script', cursive;
                font-weight: bold;
                font-size: 3rem;
                margin-bottom: 20px;
            }
            .description {
                font-size: 1.2rem;
                font-weight: 200;
                color: $textDark;
                margin-bottom: 0px;
                text-align: justify;
                white-space: pre-wrap;
            }
            .prepCook {
                color: $featureColor3;
                font-weight: bold;
                margin-bottom: 20px;
                p {
                    display: inline-block;
                    vertical-align: top;
                    margin: 0;
                    span {
                        color: $textDark;
                    }
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            .method {
                .step {
                    font-size: 1.2rem;
                    font-weight: 200;
                    color: $textDark;
                    margin-bottom: 0px;
                    span {
                        color: #b0c630;
                        font-size: 2.1rem;
                        display: inline-block;
                        margin-right: 5px;
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                        vertical-align: inherit;
                        margin-bottom: -20px;
                        padding: 5px;
                    }
                }
            }
            .buttonIcons {
                margin-top: 30px;
                text-align: right;
                @media print {
                    display: none;
                }
                .iconBtn {
                    display: inline-block;
                    vertical-align: middle;
                    &.downloadBtn {
                        margin-right: 20px;
                    }
                }
            }
            &.mobile {
                .mealImage {
                    margin-bottom: 20px;
                }
                .modeSelector {
                    margin: 20px -15px 20px -15px;
                    .methodMode,
                    .ingredientsMode {
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        color: $textDark;
                        background-color: #eaeae8;
                        font-weight: bold;
                        cursor: pointer;
                        padding: 10px;
                        transition: all 0.2s ease;
                        span {
                            margin-bottom: 4px;
                            display: inline-block;
                        }
                        img {
                            display: none;
                            @media (min-width: 350px) {
                                display: inline-block;
                                vertical-align: bottom;
                                width: 30px;
                                height: 30px;
                                margin-left: 10px;
                                opacity: 0.5;
                                transition: all 0.2s ease;
                            }
                        }
                        &.active {
                            color: white;
                            background-color: $featureColor2;
                            img {
                                filter: invert(1);
                                opacity: 1;
                            }
                        }
                    }
                    .methodMode {
                        width: 40%;
                    }
                    .ingredientsMode {
                        width: 60%;
                    }
                }
            }
            &.desktop {
                .recipeName {
                    margin-bottom: 25px;
                }
                .sectionName {
                    margin-top: 25px;
                    color: $textLight;
                    font-weight: bold;
                    font-size: 1rem;
                    .icon {
                        display: inline-block;
                        vertical-align: middle;
                        width: 30px;
                        height: 30px;
                        margin-left: 10px;
                        margin-bottom: 6px;
                        opacity: 0.5;
                    }
                }
                .left {
                    display: inline-block;
                    vertical-align: top;
                    width: 45%;
                    .ingredientsList {
                        .colLeft,
                        .colRight {
                            display: inline-block;
                            vertical-align: top;
                            width: 50%;
                        }
                        .colLeft {
                            padding-right: 5px;
                        }
                        .colRight {
                            padding-left: 5px;
                        }
                    }
                }
                .right {
                    display: inline-block;
                    vertical-align: top;
                    width: 55%;
                    padding-left: 30px;
                    .recipeName {
                        font-size: 3.5rem;
                    }
                    .prepCook {
                        font-size: 1.2rem;
                    }
                }
                @media (min-width: $break_l) {
                    .left {
                        width: 40%;
                    }
                    .right {
                        width: 60%;
                    }
                }
                @media (min-width: $break_xl) {
                    .left {
                        width: 35%;
                    }
                    .right {
                        width: 65%;
                    }
                }
            }
        }
        .single-recipe-view {
            padding-bottom: 50px;
            text-align: right;
            .recipeContent {
                text-align: left;
                background-color: #fff;
                padding: 20px;
                border-radius: 5px;
                margin-top: -40px;
                box-shadow: 0 4px 10px #0000001a;
            }
            .recipeName {
                margin: 25px 0px;
            }
            .goBack {
                margin-top: 20px;
                text-align: center;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    transform: rotate(180deg);
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                }
                @media print {
                    display: none;
                }
            }
        }
        .payment-container {
            background-color: #3d3d4b;
            color: #eaeaea;
            text-align: center;
            font-weight: 100;
            font-family: 'Roboto', sans-serif;
            .triangle {
                z-index: 1;
                position: absolute;
                top: -10px;
                left: 50%;
                transform: rotate(45deg) translateX(-50%);
                background-color: #f5f4f0;
                height: 35px;
                width: 35px;
            }
            .error {
                padding: 20px;
                color: red;
                font-size: 1.2rem;
            }
            .overlay {
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                background-color: #3d3d4beb;
                color: white;
                div {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 2rem;
                }
                &.hidden {
                    background-color: transparent;
                    z-index: -1;
                }
            }
            .row {
                padding: 80px 20px;
            }
            h2 {
                font-weight: 100;
            }
            .price-badge {
                position: absolute;
                top: -50px;
                right: 10px;
                z-index: 1;
            }
        }
    }
    &.contactPage {
        background-color: #4b4b57;
        padding-bottom: 60px;
        min-height: 70vh;
        .backgroundTransition {
            margin-top: -50px;
            height: 50px;
            background: linear-gradient(to bottom, rgba(75,75,87,0) 0%,rgba(75,75,87,1) 100%);
        }
        .contact-form-explainer {
            color: white;
            text-align: center;
            line-height: 30px;
        }
        .form {
            .fields {
                .field {
                    textarea,
                    input {
                        border-radius: 20px;
                        border: 2px solid grey;
                        box-shadow: 0px 2px 3px #00000029;
                    }
                    .errors {
                        margin-top: 2px;
                        text-align: center;
                        color: red;
                        position: absolute;
                        width: 100%;
                    }
                    .tsAndCs {
                        .checkboxField {
                            width: initial;
                        }
                    }
                    &.good.dirty input {
                        border: 2px solid #c1d72d;
                        color: #556200;
                    }
                    &.bad.dirty input {
                        border: 2px solid #fcb316;
                    }
                }
                @media (min-width: $break_xs) {
                    .field:nth-of-type(2), .field:nth-of-type(3) {
                        display: inline-block;
                        width: 50%;
                    }
                    .field:nth-of-type(2) {
                        padding-right: 15px;
                    }
                    .field:nth-of-type(3) {
                        padding-left: 15px;
                    }
                }
            }
            .field-type-recaptcha {
                margin-top: 25px;
                transform-origin: left;
                transform:scale(0.77);
                @media (min-width: $break_xs) {
                    transform: scale(1);
                    & > div > div > div {
                        margin: 0 auto;
                    }
                }
            }
            .submit {
                // margin-top: 15px;
                transform: scale(1);
                transition: transform 0.2s ease;
                display: inline-block;
                width: 100%;
                &.hidden {
                    transform: scale(0);
                }
                .button {
                    width: 100%;
                    text-align: center;
                    font-size: 1.2rem;
                }
            }
        }
        .response {
            display: none;
            padding: 10px;
            text-align: center;
            border-radius: 20px;
            font-size: 1.2rem;
            &.bad {
                background-color: rgb(250, 169, 169);
                color: darkred;
                margin-top: 30px;
            }
            &.good {
                background-color: #c1d72d;
                color: white;
                font-weight: bold;
            }
        }
        .sm-col,
        .team-col {
            text-align: center;
            p {
                margin: 0;
                padding: 20px 10px;
                color: #e4e4e4;
            }
            .smicons {
                border-bottom: 2px dashed #6b6b6b;
                .smLink {
                    width: 50px;
                    padding: 10px;
                    display: inline-block;
                    opacity: 0.7;
                    transition: opacity 0.2s ease;
                    &:hover {
                        opacity: 1;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            &.sm-col {
                margin-top: 20px;
                border-top: 2px dashed #6b6b6b;
            }
            @media (min-width: $break_xs) {
                .smicons {
                    .smLink {
                        width: 90px;
                        padding: 20px;
                    }
                }
            }
        }
    }
    &.loginPage {
        .boxWrapper {
            width: 90%;
            max-width: 500px;
            padding: 80px 15px 40px 15px;
            border-radius: 5px;
            margin: 0 auto;
            .logo {
                width: 100px;
                height: 100px;
                position: absolute;
                top: -50px;
                left: 50%;
                transform: translateX(-50%);
            }
            .login {
                .field-wrapper {
                    margin-bottom: 10px;
                    .fieldTooltip {
                        padding: 3px 9px;
                        color: red;
                    }
                }
                .link {
                    cursor: pointer;
                    span {
                        font-weight: bold;
                        color: #b0c630;
                    }
                }
                button {
                    color: #fff;
                    background-color: #b0c630;
                    outline: none;
                    border-radius: 20px;
                    width: 100%;
                    border: none;
                    padding: 10px;
                    cursor: pointer;
                    &:disabled {
                        cursor: initial;
                        opacity: 0.4;
                    }
                }
                .error {
                    color: red;
                }
            }
        }
    }
    &.registerPage {
        background-color: #4b4b57;
        .topText {
            color: white;
            padding: 0px 10px 0px 40px;
        }
        .register-video-link {
            text-align: center;
            .button {
                padding-bottom: 7px;
                img {
                    display: inline-block;
                    width: 23px;
                    height: 23px;
                    margin-left: 5px;
                    margin-bottom: 3px;
                }
            }
        }
        .loginError {
            padding: 10px;
            margin-top: 20px;
            text-align: center;
            color: red;
            font-size: 1.2rem;
        }
        .hero {
            background-position: center top;
        }
        .backgroundTransition {
            margin-top: -50px;
            height: 50px;
            background: linear-gradient(to bottom, rgba(75,75,87,0) 0%,rgba(75,75,87,1) 100%);
        }
        .userRegistration {
            background-color: $backgroundDark;
            padding-bottom: 60px;
            .overlay {
                transition: opacity 0.3s ease;
                opacity: 0;
                position: fixed;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100vh;
                background-color: #4b4b57;
                z-index: -1;
            }
            &.working {
                .overlay {
                    opacity: 0.5;
                    z-index: 999;
                }
            }
            hr {
                border-top: 2px dashed $textLight;
            }
            .registration-section {
                padding-left: 40px;
                margin-bottom: 40px;
                .stageTitle {
                    margin-bottom: 30px;
                    margin-top: 20px;
                    .stage-number {
                        display: inline-block;
                        height: 30px;
                        width: 30px;
                        line-height: 35px;
                        font-weight: bold;
                        font-size: 2rem;
                        text-align: center;
                        border-radius: 50px;
                        color: white;
                        background-color: #fcb316;
                        font-family: "Tahu", sans-serif;
                        position: absolute;
                        top: 4.5px;
                        left: -35px;
                        &.closer {
                            left: -20px;
                        }
                    }
                    h1 {
                        color: white;
                        font-size: 2.5rem;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 100;
                        margin: 0;
                        text-align: left;
                    }
                    @media (min-width: $break_xs) {
                        padding-left: 50px;
                        h1 {
                            font-size: 3rem;
                        }
                        .stage-number {
                            top: 2.5px;
                            left: -5px;
                            width: 40px;
                            height: 40px;
                            line-height: 45px;
                            font-size: 2.5rem;
                            &.closer {
                                left: -5px;
                            }
                        }
                    }
                    @media (min-width: $break_s) {
                        padding-left: 60px;
                        h1 {
                            font-size: 3rem;
                        }
                        .stage-number {
                            width: 50px;
                            height: 50px;
                            line-height: 55px;
                            font-size: 3rem;
                        }
                    }
                }
                .moreInfo {
                    text-align: left;
                    padding: 20px;
                    color: #e4e4e4;
                }
                &.commitmentLevels {
                    .moreInfo {
                        line-height: 30px;
                    }
                    .commitmentSelector {
                        padding-top: 20px;
                        padding-bottom: 20px;
                        .left {
                            z-index: 1;
                            padding: 0px;
                            .inner {
                                padding: 15px 20px;
                                background-color: white;
                                border-radius: 4px;
                                .selectorRow {
                                    padding: 28px 0px 28px 90px;
                                    .image {
                                        border-radius: 2px;
                                        height: 80px;
                                        width: 120px;
                                        background-color: lightgrey;
                                        background-size: cover;
                                        background-position: center;
                                        position: absolute;
                                        left: 0px;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                    .button {
                                        width: 100%;
                                    }
                                    .toggleButton.disabled {
                                        opacity: 0.7;
                                    }
                                }
                            }
                        }
                        .right, .under {
                            padding-left: 0px;
                            .inner {
                                background-color: #625c6d;
                                color: white;
                                padding: 30px;
                                padding-left: 90px;
                                border-radius: 0px 10px 10px 0px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 100%;
                                img {
                                    position: absolute;
                                    top: 50%;
                                    width: 100px;
                                    left: -30px;
                                    transform: translateY(-50%);
                                }
                                p {
                                    font-size: 2.4rem;
                                    margin-bottom: 0px;
                                    font-weight: 100;
                                }
                                b {
                                    font-size: 1.2rem;
                                }
                            }
                            &.under {
                                padding: 0px 20px;
                                .inner {
                                    padding-left: 30px;
                                    padding-top: 50px;
                                    position: relative;
                                    top: initial;
                                    transform: initial;
                                    border-radius: 0px 0px 10px 10px;
                                    img {
                                        top: -15px;
                                        left: 30px;
                                        width: 60px;
                                        -webkit-transform: rotate(90deg);
                                        transform: rotate(90deg);
                                    }
                                }
                            }
                        }
                    }
                }
                &.paymentSection {
                    min-height: 420px;
                    .moreInfo {
                        padding: 0px;
                    }
                    .paymentOption {
                        color: white;
                        padding: 10px;
                        font-weight: 100;
                        .paymentOption-heading {
                            font-weight: 100;
                            font-size: 2rem;
                            margin: 0px;
                            padding: 0px 10px 20px 0px;
                        }
                        & > .inner {
                            box-shadow: 0px 4px 10px #0000000f;
                            padding: 25px;
                            border-radius: 10px;
                            background-color: #625c6d;
                            min-height: 340px;
                            .paymentField, .cardField {
                                margin: 0px;
                            }
                            .tsAndCs {
                                padding: 10px 0px;
                                text-align: left;
                            }
                        }
                        &.free-trial {
                            .inner {
                                background-color: #534e5f;
                            }
                            button {
                                padding: 12px 30px;
                                border-radius: 100px;
                                border: 2px solid grey;
                                background-color: transparent;
                                color: white;
                                font-weight: bold;
                                color: #d9d9d9;
                                cursor: pointer;
                                transition: all 0.2s ease;
                                &:hover {
                                    color: white;
                                    border-color: white;
                                }
                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                        &.full-plan {
                            .moreInfo {
                                padding-right: 80px;
                            }
                            .price-badge {
                                position: absolute;
                                right: 14px;
                                top: 65px;
                            }
                            button {
                                padding: 12px 30px;
                                border-radius: 100px;
                                border: 2px solid grey;
                                background-color: transparent;
                                color: white;
                                font-weight: bold;
                                color: #d9d9d9;
                                cursor: pointer;
                                transition: all 0.2s ease;
                                &:hover {
                                    color: white;
                                    border-color: white;
                                }
                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
            input {
                border: 2px solid grey;
                box-shadow: 0px 2px 3px #00000029;
            }
            .registerForm {
                .fields {
                    .field {
                        padding-bottom: 25px;
                    }
                    @media (min-width: $break_xs) {
                        .field:nth-of-type(1), .field:nth-of-type(2) {
                            display: inline-block;
                            width: 50%;
                        }
                        .field:nth-of-type(1) {
                            padding-right: 15px;
                        }
                        .field:nth-of-type(2) {
                            padding-left: 15px;
                        }
                    }
                    .good.dirty input {
                        border: 2px solid #c1d72d;
                        color: #556200;
                    }
                    .bad.dirty input {
                        border: 2px solid #fcb316;
                    }
                    .field {
                        .errors {
                            .error {
                                position: absolute;
                                width: 100%;
                                padding: 2px;
                                text-align: center;
                                color: #fcb316;
                            }
                        }
                    }
                }
            }
            .subText {
                color: white;
                text-align: justify;
                padding-bottom: 30px;
            }
            .registerForm,
            .conditionsList {
                padding-bottom: 20px;
                border-bottom: 1px dashed $textLight;
            }
            .conditionsList {
                text-align: center;
                .category {
                    margin-bottom: 40px;
                    .conditions {
                        min-height: 51px;
                        &.addHeight {
                            min-height: 102px;
                        }
                    }
                }
                @media (min-width: $break_s) {
                    text-align: left;
                }
            }
            .userConditions {
                padding-top: 30px;
                .conditionSearch {
                    padding: 20px 0px 5px 0px;
                }
                .condition {
                    color: white;
                    font-weight: bold;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 10px 15px;
                    padding-right: 40px;
                    margin-right: 7px;
                    margin-bottom: 10px;
                    box-shadow: 0px 2px 3px #00000029;
                    cursor: pointer;
                    border-radius: 50px;
                    background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                    .outerCircle {
                        position: absolute;
                        top: 5.5px;
                        right: 5px;
                        width: 30px;
                        height: 30px;
                        padding: 3px;
                        border-radius: 50%;
                        background: linear-gradient(to right, rgba(200,224,40,1) 0%,rgba(162,191,46,1) 100%);
                        transform: rotate(-45deg);
                        .innerCircle {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            padding: 5px;
                            background: linear-gradient(to right, rgba(200,224,40,1) 0%,rgba(122,153,36,1) 100%);
                            img {
                                width: 100%;
                                margin-bottom: 8px;
                                transition: opacity 0.2s ease;
                                transform: rotate(45deg);
                                transform-origin: center;
                                opacity: 0;
                            }
                        }
                    }
                    &.selected {
                        background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(252,190,0,1) 100%);
                        .outerCircle {
                            background: linear-gradient(to right, rgba(252,205,35,1) 0%,rgba(240,145,21,1) 100%);
                            .innerCircle {
                                background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(204,89,6,1) 100%);
                                img {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    &.disabled {
                        opacity: 0.3;
                        cursor: default;
                    }
                    @media (min-width: $break_xs) {
                        padding-right: 55px;
                    }
                }
                .selectedConditions {
                    min-height: 194px;
                    padding: 20px 0px;
                    border-bottom: 1px dashed $textLight;
                    .noConditionsSelected{
                        text-align: center;
                        position: absolute;
                        color: #7b868c;
                        text-transform: uppercase;
                        width: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                        font-weight: bold;
                    }
                    .condition {
                        background: #665E77;
                        .outerCircle {
                            background: linear-gradient(to right, rgba(127,117,148,1) 0%,rgba(83,79,99,1) 100%);
                            .innerCircle {
                                background: linear-gradient(to right, rgba(83,79,99,1) 0%,rgba(62,59,77,1) 100%);
                                img {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    @media (min-width: $break_xs) {
                        min-height: 153px;
                    }
                    @media (min-width: $break_s) {
                        min-height: 143px;
                    }
                }
                .submitBtn {
                    font-size: 1.4rem;
                    margin-top: 15px;
                    background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                    border-radius: 50px;
                    text-align: center;
                    display: block;
                }
            }
        }
    }
    &.upgradePage {
        .hero {
            .inlineLogo {
                text-align: center;
                color: $heroText;
                font-size: 1.5rem;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    width: 50px;
                    width: 50px;
                    margin: 0px 10px;
                }
            }
            @media (min-width: $break_xs) {
                padding: 70px 0px 50px 0px;
                .inlineLogo {
                    font-size: 2.5rem;
                    font-weight: lighter;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
            @media (min-width: $break_s) {
                .inlineLogo {
                    font-size: 3rem;
                    img {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
            @media (min-width: $break_l) {
                padding: 100px 0px 50px 0px;
                .inlineLogo {
                    font-size: 3.5rem;
                    img {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
            @media (min-width: $break_xl) {
                padding: 110px 0px 60px 0px;
            }
            @media (min-width: $break_xxl) {
                background-size: cover;
            }
            @media (min-width: $break_xxxl) {
                background-position: center 60%;
            }
        }
    }
    //Squeeze pages
    &.sugarCravingsPage,
    &.foodAllergensPage,
    &.obesityPage,
    &.tirednessPage,
    &.sleepPage,
    &.depressionPage {
        .hero {
            @media (min-width: $break_l) {
                background-size: cover;
            }
            @media (min-width: $break_xl) {
                padding: 60px 0px 80px 0px;
            }
            @media (min-width: $break_xxl) {
                padding: 80px 0px 100px 0px;
            }
        }
        .floatingBtn {
            .floating {
                z-index: 5;
                position: absolute;
                top: -20.5px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .video-wrapper {
            margin-bottom: 20px;
        }
        .center {
            @media (min-width: $break_s) {
                position: absolute;
                left: 15px;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .sectionText,
        li {
            span {
                font-weight: bold;
            }
        }
        li {
            color: $textDark;
            @media (min-width: $break_xl) {
                font-size: 1.2rem;
            }
        }
        .inlineBtn {
            color: $featureColor2;
            font-weight: bold;
            margin-left: 5px;
        }
        .tiltedBook {
            max-width: 300px;
            margin: 20px auto;
            @media (min-width: $break_s) {
                transform: rotate(10deg);
                max-width: 200px;
                margin: 40px auto 0px auto;
            }
            img {
                width: 100%;
            }
        }
        .button-wrap {
            text-align: center;
        }
        .button {
            &.desktop {
                display: none;
            }
            @media (min-width: $break_s) {
                &.mobile {
                    display: none;
                }
                &.desktop {
                    display: inline-block;
                }
            }
        }
        .sugarPageVid {
            margin: 6px 0px 20px 0px;
            @media (min-width: $break_s) {
                margin: 0px 0px 40px 0px;
            }
        }
        .imageFrame {
            margin-bottom: 20px;
            @media (min-width: $break_s) {
                margin-bottom: 0px;
                &.center {
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .darkSection {
            .sectionText,
            li,
            p {
                line-height: 1.7rem;
                @media (min-width: $break_xl) {
                    line-height: 2rem;
                }
            }
            li,
            p {
                span {
                    color: $featureColor3;
                    font-weight: bold;
                }
            }
            .greenText {
                color: $featureColor3;
                font-weight: bold;
                @media (min-width: $break_xl) {
                    font-size: 1.2rem;
                }
            }
        }
    }
    &.tirednessPage {
        .hero {
            @media (min-width: $break_l) {
                background-size: cover;
            }
        }
    }
    &.somethingElsePage {
        .conditionRow {
            text-align: center;
            padding: 10px 0px 20px 0px;
            .condition {
                cursor: pointer;
                color: white;
                font-weight: bold;
                display: inline-block;
                vertical-align: middle;
                padding: 10px 15px;
                margin-right: 7px;
                margin-bottom: 10px;
                box-shadow: 0px 2px 3px #00000029;
                border-radius: 50px;
                background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
            }
        }
        .arrowDiv {
            background-color: $backgroundLight;
            width: 40px;
            height: 40px;
            transform: translateX(-50%) rotate(45deg);
            position: absolute;
            left: 50%;
            bottom: -15px;
            z-index: 1;
        }
        .darkSection {
            .imgHeading {
                width: 80%;
                max-width: 700px;
                display: block;
                margin: 0 auto 30px auto;
            }
            .buttonWrap {
                text-align: center;
                margin-top: 30px;
            }
        }
    }
    &.usefulInfo,
    &.somethingElsePage {
        background-color: $backgroundLight;
        .hero {
            background-size: cover;
            background-position: center;
            padding: 90px 0px 30px 0px;
            .hero-content {
                .textImg {
                    width: 55%;
                }
            }
            @media (min-width: $break_xs) {
                background-position: center 40%;
                padding: 110px 0px 40px 0px;
                .hero-content {
                    .textImg {
                        max-width: 350px;
                    }
                }
            }
            @media (min-width: $break_s) {
                padding: 130px 0px 40px 0px;
                .hero-content {
                    .textImg {
                        max-width: 400px;
                    }
                }
            }
            @media (min-width: $break_l) {
                padding: 150px 0px 40px 0px;
                .hero-content {
                    .textImg {
                        max-width: 500px;
                    }
                }
            }
            @media (min-width: $break_xl) {
                padding: 160px 0px 40px 0px;
                .hero-content {
                    .textImg {
                        max-width: 600px;
                    }
                }
            }
            @media (min-width: $break_xxl) {
                padding: 190px 0px 50px 0px;
                .hero-content {
                    .textImg {
                        max-width: 700px;
                    }
                }
            }
            @media (min-width: $break_xxxl) {
                padding: 220px 0px 50px 0px;
                .hero-content {
                    .textImg {
                        max-width: 800px;
                    }
                }
            }
        }
    }
    &.usefulInfo {
        .sideNav {
            background-color: #eae9e4;
            .sideLink {
                cursor: pointer;
                background-color: #eae9e4;
                padding: 15px;
                color: $textDark;
                text-decoration: none;
                font-weight: bold;
                display: block;
                &.selected {
                    color: $featureColor3;
                    background-color: $backgroundLight;
                }
                &:hover {
                    color: white;
                    background: linear-gradient(45deg, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                }
            }
            @media (min-width: $break_xs) {
                .sideLink {
                    cursor: pointer;
                    padding: 15px calc((100% - 510px)/2);
                }
            }
            @media (min-width: $break_s) {
                .sideLink {
                    cursor: pointer;
                    padding: 15px calc((100% - 690px)/2);
                }
            }
            @media (min-width: $break_l) {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                max-width: 220px;
                .sideLink {
                    padding: 15px;
                }
            }
            @media (min-width: $break_xl) {
                max-width: 275px;
            }
            @media (min-width: $break_xxl) {
                max-width: 310px;
            }
        }
        .content-container {
            padding-top: 50px;
            padding-bottom: 40px;
            .contentBlock {
                background-color: white;
                border-radius: 10px;
                padding: 20px;
                margin-top: 20px;
            }
            .downloadBlock {
                margin-top: 20px;
                h5 {
                    color: $featureColor4;
                    font-weight: bold;
                }
                p {
                    margin: 0;
                    color: $textDark;
                }
                .button {
                    margin-top: 10px;
                    img {
                        width: 20px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
                @media (min-width: $break_s) {
                    .text {
                        padding-right: 200px;
                    }
                    .button {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        margin-top: 0px;
                    }
                }
            }
            .snacks-row {
                margin-top: 30px;
            }
            .topTips,
            .weeklyGuidelines {
                .contentBlock {
                    padding-top: 40px;
                }
                .col-12 {
                    text-align: center;
                    p {
                        text-align: justify;
                    }
                    img {
                        width: 100%;
                        max-width: 75px;
                        margin: 0 auto;
                        display: block;
                        margin-bottom: 15px;
                    }
                    @media (min-width: $break_s) {
                        text-align: left;
                        img {
                            margin-bottom: 0px;
                        }
                    }
                    &:nth-child(even) {
                        margin-bottom: 40px;
                    }
                }
            }
            .dosAndDonts {
                .blog-column {
                    padding: 0px;
                    & > div {
                        h2 {
                            margin-bottom: 20px;
                        }
                        padding: 20px;
                        border-radius: 10px;
                        color: white;
                        ul > li {
                            margin-bottom: 5px;
                        }
                    }
                    &:nth-child(2) > div {
                        background-color: #2ebbd9;
                    }
                    &:nth-child(3) {
                        display: none;
                        @media (min-width: $break_s) {
                            display: initial;
                        }
                        img {
                            width: 100%;
                            position: absolute;
                            top: 40%;
                            transform: translateY(-50%);
                        }
                    }
                    &:nth-child(4) {
                        img {
                            width: 100%;
                        }
                        display: initial;
                        @media (min-width: $break_s) {
                            display: none;
                        }
                    }
                    &:nth-child(5) > div {
                        background-color: #cbd517;
                    }
                }
                .downloadBlock {
                    margin-top: 40px;
                }
            }
            .dirtyDozen {
                text-align: center;
                .contentBlock {
                    padding-top: 40px;
                    padding-bottom: 40px;
                }
                .col-12 {
                    h1 {
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                    ol {
                        padding-left: 0px;
                        list-style: none;
                        li {
                            margin-bottom: 10px;
                        }
                    }
                    &:nth-child(2) {
                        margin-top: 30px;
                        margin-bottom: 40px;
                        background-color: #f5f4f0;
                        border-radius: 10px;
                        text-align: center;
                        div {
                            top: 50%;
                            transform: translateY(-50%);
                            &:before {
                                content: '"';
                                color: $featureColor3;
                                font-size: 6rem;
                                position: relative;
                                top: 65px;
                            }
                            &:after {
                                content: '"';
                                color: $featureColor3;
                                font-size: 6rem;
                                position: relative;
                                bottom: 30px;
                            }
                        }
                    }
                }
                @media (min-width: $break_s) {
                    text-align: left;
                    .col-12 {
                        ol {
                            padding-left: 25px;
                            list-style: decimal;
                        }
                        &:nth-child(2) {
                            margin-top: 0px;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            .overview {
                .headingText {
                    margin-top: 10px;
                    p {
                        line-height: 2rem;
                    }
                }
                .contentBlock {
                    padding-top: 40px;
                }
                .col-12 {
                    h1 {
                        font-family: 'Dancing Script', cursive;
                        font-weight: bold;
                        margin: 0;
                        font-size: 3rem;
                        color: #7c868d;
                        margin-bottom: 15px;
                    }
                    &:nth-child(odd) {
                        text-align: center
                    }
                    &:nth-child(even) {
                        margin-bottom: 40px;
                    }
                }
            }
            .gutBacteria {
                .contentBlock {
                    padding-top: 40px;
                }
                .col-12 {
                    &:nth-child(1) {
                        div {
                            p {
                                line-height: 2rem;
                            }
                        }
                    }
                    &:nth-child(2) {
                        background-color: #f5f4f0;
                        border-radius: 10px;
                        div {
                            top: 50%;
                            transform: translateY(-50%);
                            h5 {
                                margin-bottom: 20px;
                                &:first-child {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
            .programme {
                .headingText {
                    margin-top: 10px;
                    text-align: justify;
                    line-height: 1.7rem;
                }
                .contentBlock {
                    padding-top: 40px;
                    h1,h2,h3 {
                        margin-bottom: 20px;
                    }
                    h4,h5,h6 {
                        margin-bottom: 12px;
                    }
                    p {
                        text-align: justify;
                        line-height: 1.7rem;
                    }
                    @media (min-width: $break_s) {
                        column-count: 2;
                        column-gap: 40px;
                    }
                }
            }
            .rebalancing {
                .contentBlock {
                    padding-top: 40px;
                }
                .col-12 {
                    text-align: center;
                    h4 {
                        padding: 30px 0px 20px 0px;
                    }
                    h5 {
                        padding: 15px 0px
                    }
                    p {
                        text-align: justify;
                        line-height: 1.7rem;
                    }
                    img {
                        width: 100%;
                        max-width: 75px;
                        margin: 0 auto;
                        display: block;
                        margin-bottom: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &.col-md-4 {
                        img {
                            max-width: 125px;
                        }
                    }
                    @media (min-width: $break_s) {
                        text-align: left;
                        img {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
    &.pressPage {
        .otherTabs {
            border-bottom: 1px solid #d9d9d7;
            text-align: center;
            .tab {
                display: inline-block;
                vertical-align: bottom;
                border-radius: 5px;
                background-color: #eae9e4;
                color: $textDark;
                transition: all 0.2s ease;
                margin: 5px;
                padding: 5px 15px;
                cursor: pointer;
                &:last-child {
                    margin-right: 0px;
                }
                &.selected {
                    color: white;
                    background-color: $featureColor1;
                }
            }
        }
        .search {
            input {
                border: 2px solid #d9d9d7;
            }
        }
        .date {
            display: flex;
            border-radius: 5px;
            overflow: hidden;
            width: 108px;
            margin-bottom: 10px;
            .day,
            .wrapper {
                flex: 1;
                text-align: center;
            }
            .day {
                background-color: white;
                color: $textDark;
                font-weight: bold;
                font-size: 2rem;
                line-height: 54px;
            }
            .wrapper {
                display: flex;
                flex-direction: column;
                .month,
                .year {
                    flex: 1;
                    text-align: center;
                    color: white;
                    padding: 3px 0px;
                    font-size: 1rem;
                }
                .month {
                    background-color: $featureColor1;
                    font-weight: bold;
                }
                .year {
                    background-color: #a2bf37;
                }
            }
        }
        .otherResults,
        .events {
            min-height: 40vh;
        }
        .release {
            padding: 25px 0px;
            border-bottom: 1px solid #d9d9d7;
            .text {
                color: $textDark;
                h2 {
                    font-weight: bold;
                }
                p {
                    margin-bottom: 0px;
                    font-weight: 300;
                }
            }
            .button {
                text-transform: uppercase;
                margin-top: 10px;
                background: linear-gradient(to right, rgba(162,191,55,1) 0%,rgba(193,215,45,1) 100%);
            }
        }
        .events .release {
            .date {
                .month {
                    background-color: $featureColor3;
                }
                .year {
                    background-color: #d69056;
                }
            }
            .button {
                background: linear-gradient(to right, #d69056 0%, #fcb316 100%);
            }
        }
        @media (min-width: $break_xs) {
            .date {
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
            }
            .otherTabs {
                text-align: left;
                .tab {
                    margin: 0px 5px 0px 0px;
                    border-radius: 5px 5px 0px 0px;
                }
            }
            .release {
                padding: 25px 0px 25px 130px;
            }
        }
        @media (min-width: $break_l) {
            .release {
                padding-right: 275px;
                .button {
                    margin-top: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    right: 0px;
                }
            }
        }
        .page-changer {
            text-align: center;
            padding: 20px 0px 30px 0px;
            .page-btn {
                display: inline-block;
                color: $textDark;
                background-color: #eae9e4;
                border-radius: 50%;
                margin: 0px 2px;
                cursor: pointer;
                font-weight: bold;
                font-size: 1.3rem;
                width: 40px;
                height: 40px;
                line-height: 40px;
                transition: all 0.2s ease;
                opacity: 1;
                &.current {
                    color: white;
                    background-color: $textDark;
                }
                &.disabled {
                    opacity: 0.3;
                    cursor: initial;
                }
            }
        }
    }
    &.howItWorksPage {
        .sectionTitle {
            line-height: 2.2rem;
        }
        iframe {
            height: 250px;
            width: 100%;
        }
        .top-btn {
            z-index: 1;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translate(-50%, 50%);
        }
        .midContent {
            ul {
                padding-left: 0px;
                li {
                    list-style: none;
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background-image: url("/static/media/icon-arrow-yellow.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 5px;
                        vertical-align: bottom;
                    }
                }
            }
        }
        .planCard {
            img {
                width: 100%;
            }
            max-width: 350px;
            margin: 0 auto;
        }
        .darkSection {
            background-size: 0;
            h1 {
                color: white;
                margin-bottom: 10px;
                text-align: center;
                &:last-child {
                    margin-bottom: 30px;
                }
                span {
                    font-weight: bold;
                }
            }
            .listItem {
                padding-left: 50px;
                img {
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 30px;
                }
                span {
                    font-weight: bold;
                    color: $featureColor3;
                }
            }
            .triangle {
                width: 25px;
                height: 25px;
                background-color: #3c444c;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-50%, 50%) rotate(45deg);
            }
        }
        @media (min-width: $break_s) {
            iframe {
                height: 200px;
            }
            .darkSection {
                background-size: 35%;
                background-repeat: no-repeat;
                background-position: left bottom;
                h1 {
                    text-align: left;
                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        @media (min-width: $break_l) {
            .sectionTitle {
                font-size: 2.5rem;
                line-height: 2.7rem;
            }
            iframe {
                height: 250px;
            }
            .darkSection {
                background-size: 28%;
                h1 {
                    font-size: 2.5rem;
                    margin-bottom: 10px;
                }
            }
        }
        @media (min-width: $break_xl) {
            .darkSection {
                background-size: 22%;
            }
            iframe {
                height: 300px;
            }
        }
    }
    &.adminPage {
        background-color: $backgroundLight;
        padding-bottom: 240px;
        // min-height: 70vh;
        overflow: hidden;
        .hero {
            background-size: 100%;
            background-position: center top;
            height: 200px;
        }
        .bg-transition {
            height: 50px;
            width: 100%;
            position: absolute;
            top: 150px;
            left: 0;
            right: 0;
            background: linear-gradient(to bottom, rgba(245,244,240,0) 0%,rgba(245,244,240,1) 100%);
        }
        .adminNavigation {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            a {
                z-index: 3;
                text-align: center;
                padding: 10px;
                display: inline-block;
                vertical-align: middle;
                width: 25%;
                text-decoration: none;
                background-color: #eae9e4;
                transition: background-color 0.2s ease;
                img {
                    width: 30px;
                    height: 30px;
                    display: block;
                    opacity: 0.7;
                    margin: 0 auto;
                }
                span {
                    display: block;
                    color: $textDark;
                    font-weight: bold;
                    margin-top: 5px;
                    font-size: 0.6rem;
                    transition: color 0.2s ease;
                }
                &.selected {
                    background-color: $textDark;
                    span {
                        color: #eae9e4;
                    }
                    img {
                        filter: invert(1);
                        opacity: 0.85;
                    }
                }
            }
        }
        .adminTab {
            margin-top: -100px;
            .conditions {
                label {
                    color: $textDark;
                    margin-left: 0px;
                    margin-bottom: 15px;
                    font-size: 1.2rem;
                }
                .condition {
                    color: white;
                    font-weight: bold;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 10px 15px;
                    margin-right: 7px;
                    margin-bottom: 10px;
                    // box-shadow: 0px 2px 3px #00000029;
                    cursor: pointer;
                    border-radius: 50px;
                    background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
                    &.selected {
                        background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(252,190,0,1) 100%);
                    }
                }
            }
            .mealPlan {
                h2 {
                    color: #9e9e9e;
                    font-weight: bold;
                    margin-top: 30px;
                    font-size: 1.6rem;
                    border-top: 2px dashed #cecece;
                    padding: 10px;
                    padding-top: 30px;
                }
                .day {
                    padding-left: 40px;
                    background-color: $backgroundLight;
                    /* border-radius: 50px 5px 5px 50px; */
                    border-radius: 2px;
                    margin-bottom: 4px;

                    .dayName {
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 2rem;
                        font-family: "Tahu", sans-serif;
                        color: $featureColor4;
                    }
                }
                .meal-plan-recipe {
                    .recipe-inner {
                        padding: 8px;
                        text-align: center;
                        min-height: 150px;
                        border-radius: 2px;
                        .mealImage {
                            background-color: $backgroundLight;
                            height: 100px;
                            width: 100%;
                            border-radius: 2px;
                            background-position: center;
                            background-size: cover;
                        }
                        .rank {
                            width: 100%;
                            font-weight: bold;
                            opacity: 0.6;
                            border-radius: 0px 0px 2px 2px;
                            margin-top: -21px;
                            &.neutral {
                                color: #139db1;
                                background-color: #c7f0ff;
                            }
                            &.good {
                                color: #0b3600;
                                background-color: #aac630;
                            }
                            &.bad {
                                color: #b11313;
                                background-color: #ffc7c7;
                            }
                        }
                        .name {
                            padding-top: 10px;
                        }
                    }
                }
            }
            .partnerRegistration {
                h2 {
                    color: #9e9e9e;
                    font-weight: bold;
                    margin-top: 30px;
                    font-size: 1.6rem;
                    border-top: 2px dashed #cecece;
                    padding: 10px;
                    padding-top: 30px;
                }
                .partnerUrlButton {
                    padding-bottom:30px;
                }
                .registration {
                    padding-left: 40px;
                    padding-bottom: 10px;
                    background-color: $backgroundLight;
                    /* border-radius: 50px 5px 5px 50px; */
                    border-radius: 2px;
                    margin-bottom: 4px;

                    .monthName {
                        padding-top: 20px;
                        font-weight: bold;
                        color: #9e9e9e;
                        font-size: 1.2rem;
                    }
                }
                .registrationRow {
                    .row-inner {
                        padding: 8px;
                        text-align: center;
                        min-height: 150px;
                        border-radius: 2px;
                        .name {
                            padding-top: 10px;
                        }
                    }
                }
            }
            .title {
                color: $featureColor4;
                font-family: 'Dancing Script', cursive;
                font-weight: bold;
                font-size: 3rem;
                margin-bottom: 15px;
            }
            .settings {
                background-color: white;
                padding: 15px;
                .sInner {
                    min-height: 500px;
                    background-color: #eae9e4;
                    padding: 15px;
                }
            }
            .tester {
                background-color: white;
                padding: 15px;
                .inner {
                    min-height: 400px;
                    background-color: #eae9e4;
                    padding: 15px;
                    label {
                        color: $textDark;
                        margin-left: 0px;
                        margin-bottom: 15px;
                        font-size: 1.2rem;
                    }
                    .button {
                        margin: 20px 0px;
                        width: 50%;
                        display: inline-block;
                        transition: all 0.2s ease;
                        opacity: 1;
                        text-align: center;
                        &.left {
                            border-radius: 50px 0px 0px 50px;
                        }
                        &.right {
                            border-radius: 0px 50px 50px 0px;
                            background-color: $featureColor3;
                            &:hover {
                                background-color: #dc9e19;
                            }
                        }
                        &.disabled {
                            opacity: 0.7;
                        }
                    }
                    .recipe {
                        padding: 1px;
                        width: 100%;
                        display: inline-block;
                        @media (min-width: $break_s) {
                            width: 50%;
                        }
                        .recipe-inner {
                            padding: 5px;
                            background-color: white;
                            padding-left: 70px;
                            min-height: 60px;
                            border-radius: 2px;
                            .name {
                                white-space: pre;
                                overflow: hidden;
                                line-height: 50px;
                            }
                            .mealImage {
                                background-color: $backgroundLight;
                                position: absolute;
                                left: 5px;
                                top: 5px;
                                height: 50px;
                                width: 50px;
                                border-radius: 3px;
                                background-position: center;
                                background-size: cover;
                            }
                        }
                    }
                }
            }
            .itemList {
                background-color: white;
                padding: 15px;
                min-height: 780px;
                & > input {
                    margin-bottom: 10px;
                    border-radius: 0px;
                    background-color: $backgroundLight;
                }
                .item,
                .addItem {
                    padding: 10px;
                    background-color: #eae9e4;
                    // transition: background-color 0.2s;
                    color: $textDark;
                    font-weight: bold;
                    margin-bottom: 3px;
                    padding-right: 65px;
                    cursor: pointer;
                    .options {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        img {
                            margin-right: 5px;
                            width: 20px;
                            height: 20px;
                            opacity: 0.6;
                            &:last-child {
                                margin-right: none;
                            }
                        }
                    }
                }
                .itemCreator {
                    background-color: #eae9e4;
                    margin-bottom: 3px;
                    // padding-bottom: 30px;
                    // text-align: right;
                    .heading {
                        padding: 10px;
                        font-weight: bold;
                        margin-bottom: 3px;
                        padding-right: 45px;
                        background-color: $textDark;
                        color: white;
                        text-align: left;
                        text-transform: uppercase;
                    }
                    .field {
                        input, textarea {
                            border-radius: 0px;
                        }
                    }
                    .editArea {
                        padding: 15px;
                        .recipeLink,
                        .postLink {
                            text-align: center;
                            padding-left: 10px;
                            a {
                                display: inline-block;
                                font-weight: bold;
                                color: $featureColor4;
                                font-size: 1.2rem;

                            }
                        }
                        .checkBoxWrapper {
                            .checkboxField {
                                padding-left: 0px;
                                label {
                                    color: $featureColor2;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                }
                                .inner {
                                    left: 0;
                                }
                            }
                        }
                        .baseWeight {
                            max-width: 127.56px;
                        }
                        .csvWrapper {
                            text-align: left;
                        }
                        .ingredientRecipe {
                            text-align: left;
                            margin-bottom: 15px;
                            font-size: 1.2rem;
                            font-weight: bold;
                            padding-left: 10px;
                            span {
                                &.name {
                                    color: $featureColor2;
                                }
                                &.value {
                                    color: $textDark;
                                    display: inline-block;
                                    padding: 5px 10px;
                                    background-color: #cacaca;
                                    margin-left: 10px;
                                    padding-right: 30px;
                                    img {
                                        position: absolute;
                                        width: 30px;
                                        height: 30px;
                                        top: 2.5px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        .demographic-list {
                            .demographic-add {
                                color: white;
                                font-weight: bold;
                                text-align: center;
                                padding: 10px;
                                cursor: pointer;
                                background-color: $featureColor2;
                            }
                            .demographic {
                                border: 2px dashed #cacaca;
                                padding: 25px 10px 10px 10px;
                                margin-bottom: 15px;
                                .remove-btn {
                                    position: absolute;
                                    top: 5px;
                                    right: 5px;
                                    width: 20px;
                                    cursor: pointer;
                                }
                                .flex-container {
                                    display: flex;
                                    flex-direction: column;
                                    @media (min-width: $break_l) {
                                        flex-direction: row;
                                    }
                                    .field {
                                        margin-right: 15px;
                                    }
                                    .range {
                                        display: flex;
                                    }
                                }
                                .field {
                                    &.numberField {
                                        max-width: 80px;
                                    }
                                    &.dropdownField {
                                        width: 100%;
                                        max-width: 150px;
                                        .dropdown {
                                            width: 100%;
                                        }
                                    }
                                    label {
                                        padding-bottom: 10px;
                                        margin-bottom: 0px;
                                    }
                                }
                                .rules {
                                    label {
                                        color: #b0c630;
                                        margin-left: 0px;
                                        font-size: 1.2rem;
                                        padding: 10px 0px 20px 10px
                                    }
                                    .rule {
                                        margin-bottom: 7px;
                                        display: flex;
                                        padding-right: 30px;
                                        .remove-rule {
                                            width: 20px;
                                            position: absolute;
                                            top: 11px;
                                            right: 0px;
                                            cursor: pointer;
                                        }
                                        .field {
                                            margin-bottom: 0px;
                                            &.dropdownField  {
                                                max-width: 270px;
                                            }
                                        }
                                        .range {
                                            margin-left: 15px;
                                            display: flex;
                                            .arrow-icon {
                                                margin: 0px 15px;
                                                width: 25px;
                                            }
                                            .measurement {
                                                line-height: 42px;
                                                margin-left: 5px;
                                                font-weight: bold;
                                                color: $textDark;
                                            }
                                        }
                                    }
                                    .add-rule {
                                        margin-top: 15px;
                                        color: white;
                                        font-weight: bold;
                                        text-align: center;
                                        padding: 10px;
                                        cursor: pointer;
                                        background-color: $featureColor3;
                                    }
                                }
                            }
                        }
                    }
                    .closeIcon {
                        opacity: 0.6;
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        z-index: 4;
                        cursor: pointer;
                    }
                    .recipeLinks {
                        text-align: left;
                        label {
                            color: $featureColor2;
                            margin-left: 0px;
                            font-size: 1.2rem;
                            margin-bottom: 10px;
                            padding: 10px 0px 20px 10px;
                        }
                        .linkedRecipe {
                            cursor: initial;
                            background-color: #cacaca;
                            img {
                                cursor: pointer;
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                top: 10px;
                                right: 10px;
                            }
                        }
                    }
                    .tagManager, .ingredientManager {
                        text-align: left;
                        padding: 15px;
                        border-top: 2px dashed #cacaca;
                        label {
                            color: $featureColor2;
                            margin-left: 0px;
                            font-size: 1.2rem;
                            margin-bottom: 10px;
                            padding: 10px 0px 20px 10px;
                        }
                        .checkboxField label {
                            padding: 0px 0px 0px 40px;
                        }
                        .tagItem {
                            background-color: #cacaca;
                        }
                        .currentTags {
                            margin-bottom: 15px;
                        }
                        .tagEditing,
                        .tagEditor {
                            background-color: $backgroundLight;
                            padding: 15px;
                            .heading {
                                margin: -15px -15px 15px -15px;
                            }
                            .controls {
                                text-align: center;
                                .button {
                                    border-radius: 0px;
                                    &.cancelEdit {
                                        background-color: rgb(163, 24, 24);
                                        &:hover {
                                            background-color: darkred;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .nutritionals {
                        border-top: 2px dashed #cacaca;
                        padding: 15px 0px;
                        text-align: left;
                        .nutritionalGroup {
                            margin-bottom: 30px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                            .nutritional {
                                display: inline-block;
                                vertical-align: top;
                                padding: 3px;
                                margin-bottom: 10px;
                                .inner {
                                    padding-right: 35px;
                                    input {
                                        text-align: center;
                                        width: 125px;
                                        padding: 5px;
                                        &::placeholder {
                                            color: rgb(207, 207, 207);
                                        }
                                    }
                                }
                                .field {
                                    margin: 0px;
                                }
                                .measurement {
                                    position: absolute;
                                    bottom: 8px;
                                    right: 0px;
                                    width: 30px;
                                    text-align: left;
                                    color: #adadad;
                                    font-weight: bold;
                                }
                                label {
                                    max-width: 100%;
                                    overflow-x: hidden;
                                    font-size: 1rem;
                                    padding: 0px 5px;
                                }
                            }

                        }
                    }
                    .group {
                        text-align: left;
                        & > label {
                            padding: 10px;
                        }
                        label {
                            color: $featureColor2;
                            margin-left: 0px;
                            font-size: 1.2rem;
                            margin-bottom: 10px;
                        }
                        .field {
                            @media (min-width: $break_s) {
                                display: inline-block;
                                vertical-align: middle;
                                width: 20%;
                            }
                        }
                        &.checkboxGroup {
                            .checkboxField {
                                label{
                                    font-size: 1rem;
                                    color: #5e5d5b;
                                }
                            }
                        }
                    }
                    .done-btn,
                    .delete-btn {
                        margin-top: 20px;
                        // font-size: 1.2rem;
                        border-radius: 0px;
                        text-align: center;
                        width: 50%;
                        display: inline-block;
                        vertical-align: middle;
                        &.disabled {
                            opacity: 0.5;
                            cursor: default;
                        }
                    }
                    .delete-btn {
                        background-color: rgb(163, 24, 24);
                        &:hover {
                            background-color: darkred;
                        }
                    }
                    .mode-controls {
                        padding: 30px 20px;
                        .button {
                            border-radius: 0px;
                            width: 50%;
                            transition: all 0.2s ease;
                            text-align: center;
                            &.current {
                                cursor: default;
                                color: $textDark;
                                background-color: inherit;
                            }
                        }
                    }
                    .ingredientManager {
                        .ingredients {
                            .ingredient {
                                padding: 10px;
                                background-color: #cacaca;
                                color: #596268;
                                font-weight: bold;
                                margin-bottom: 3px;
                                padding-right: 45px;
                                text-align: left;
                                transition: background-color 0.2s ease;
                                cursor: pointer;
                                img {
                                    position: absolute;
                                    right: 10px;
                                    top: 10px;
                                    width: 20px;
                                    height: 20px;
                                    opacity: 0.6;
                                }
                                .reorder {
                                    position: absolute;
                                    right: 35px;
                                    top: 2px;
                                    font-size: 1.7rem;
                                    & > span {
                                        line-height: 20px;
                                        padding: 0px 10px;
                                        display: inline-block;
                                        &.reorderArrow {
                                            transform: rotate(-90deg);
                                        }
                                    }
                                }
                                .reorderDrag {
                                    cursor: move;
                                }
                                &.isDragTarget {
                                    background-color: $featureColor2;
                                }
                            }
                        }
                    }
                }
                .pageChanger {
                    margin-top: 15px;
                    text-align: center;
                    .pageDrop {
                        margin: 0px 10px;
                    }
                    .left,
                    .right {
                        display: inline-block;
                        vertical-align: middle;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        opacity: 1;
                        transition: opacity 0.2s ease;
                        &.disabled {
                            opacity: 0.5;
                            cursor: initial;
                        }
                    }
                }
                .addItem {
                    background-color: $featureColor2;
                    color: white;
                    span{
                        position: absolute;
                        right: 13px;
                        top: 1px;
                        font-size: 1.9rem;
                    }
                }
            }
            &.IngredientsTab {
                .imageField {
                    .image-preview > div {
                        background-size: contain !important;
                        background-repeat: no-repeat;
                    }
                }
            }
            &.questionnairesTab {
                .result {
                    padding: 10px;
                    .inner {
                        padding: 20px;
                        background-color: white;
                        border-radius: 4px;
                        box-shadow: 0px 1px 7px #00000024;
                        label {
                            color: $textDark;
                            margin-bottom: 30px;
                            margin-left: 0px;
                            font-size: 1.2rem;
                            height: 80px;
                        }
                    }
                }
            }
        }
        @media (min-width: $break_xs) {
            .hero {
                height: 300px;
            }
            .bg-transition {
                top: 250px;
            }
            .adminNavigation {
                a {
                    span {
                        font-size: 0.8rem;
                    }
                }
            }
            .adminTab {
                margin-top: -150px;
            }
        }
        @media (min-width: $break_s) {
            .hero {
                background-size: cover;
                background-position: center;
            }
            .adminNavigation {
                a {
                    span,
                    img {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span {
                        margin-top: 2px;
                        margin-left: 5px;
                    }
                }
            }
        }
        @media (min-width: $break_l) {
            .hero {
                height: 350px;
            }
            .bg-transition {
                height: 100px;
            }
            .adminNavigation {
                display: inline-block;
                width: initial;
                top: 150px;
                bottom: initial;
                width: 175px;
                a {
                    text-align: left;
                    margin: 0;
                    margin-bottom: 5px;
                    display: block;
                    width: 100%;
                    padding: 10px 15px;
                    span {
                        font-size: 0.9rem;
                    }
                }
            }
            .adminTab {
                margin-top: -200px;
            }
        }
        @media (min-width: $break_xl) {
            .hero {
                height: 400px;
            }
            .bg-transition {
                top: 300px;
            }
            .adminTab {
                margin-top: -250px;
            }
        }
        @media (min-width: $break_xxl) {
            .hero {
                height: 450px;
            }
            .bg-transition {
                top: 350px;
            }
            .adminNavigation {
                width: 250px;
                a {
                    padding: 10px 50px;
                }
            }
            .adminTab {
                margin-top: -300px;
            }
        }
        @media (min-width: $break_xxxl) {
            .hero {
                height: 500px;
            }
            .bg-transition {
                top: 400px;
            }
            .adminTab {
                margin-top: -350px;
            }
        }
        @media (min-width: 2000px) {
            .hero {
                height: 550px;
            }
            .bg-transition {
                top: 450px;
            }
            .adminTab {
                margin-top: -400px;
            }
        }
    }
}

.DayPicker-NavBar {
    z-index: 3;
}

.wiggle {
    animation-name: wiggle;
    animation-duration: 0.3s;
}

@keyframes wiggle {
    0% {transform: rotate(5deg);}
    25% {transform: rotate(-5deg);}
    50% {transform: rotate(3deg);}
    75% {transform: rotate(-3deg);}
    100% {transform: rotate(0deg);}
}

.popup-content {
    padding: 0px !important;
    border: 0px !important;
}

.admin-popup-content {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 15px 20px !important;
    background-color: white !important;
    box-shadow: 0px 1px 10px #b0c630 !important;
    border-radius: 10px !important;
    font-size: 1rem !important;
    text-align: center !important;
    white-space: pre !important;
    z-index: 999999999999;
}

div .partnerToken {
    margin-top: 2em;

    .topTextRight {
        margin-top: 0.5em;
        color: white;
        text-align: right;
    }

    .topText {
        color: white;
        text-align: center;
    }
    .button {
        display: inline-block;
        cursor: pointer;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        background-color: $featureColor2;
        transition: background-color 0.2s ease;
        padding: 10px 28px;
        border: none;
        border-radius: 50px;
        &:hover {
            background-color: #9fb32e;
            color: white;
            text-decoration: none;
        }
        &:focus {
            outline: none;
        }
    }
}

.restartPlan {
    background-color: #4b4b57;
    text-align: left;
    padding-top: 5%;
    .restartPlan-margins {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        .topTextRight {
            color: white;
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
            font-size: 1.8rem;
            margin-top: 0.2em;
            margin-bottom: 2em;
            text-align: right;
            align-items: center;
            position: inherit;
        }
        .current-code{
            color: white;
            text-align: right;
            margin: 0 6% 6% 0;

            .title {
                font-family: 'Roboto', sans-serif;
                font-weight: 100;
                font-size: 1.2rem;
            }
            .code{
                font-size: 2rem;
                font-weight: bold;
            }
        }
        .InputError{
            color: red;
            font-size: 1.2rem;
            text-align: center;
        }
    }
    .hero-content {
        .textImg {
            width: 90%;
            max-width: 700px;
            margin: 0 auto 15px auto;
        }
        .heroText {
            text-align: center;
            font-size: 2rem;
            color: white;
            margin-bottom: 10px;
        }
    }
    .userConditions {
        padding-top: 30px;
        .condition {
            color: white;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
            padding: 10px 15px;
            padding-right: 40px;
            margin-right: 7px;
            margin-bottom: 10px;
            box-shadow: 0px 2px 3px #00000029;
            cursor: pointer;
            border-radius: 50px;
            background: linear-gradient(to right, rgba(162,191,46,1) 0%,rgba(193,215,45,1) 100%);
            .outerCircle {
                position: absolute;
                top: 5.5px;
                right: 5px;
                width: 30px;
                height: 30px;
                padding: 3px;
                border-radius: 50%;
                background: linear-gradient(to right, rgba(200,224,40,1) 0%,rgba(162,191,46,1) 100%);
                transform: rotate(-45deg);
                .innerCircle {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    padding: 5px;
                    background: linear-gradient(to right, rgba(200,224,40,1) 0%,rgba(122,153,36,1) 100%);
                    img {
                        width: 100%;
                        margin-bottom: 8px;
                        transition: opacity 0.2s ease;
                        transform: rotate(45deg);
                        transform-origin: center;
                        opacity: 0;
                    }
                }
            }
            &.selected {
                background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(252,190,0,1) 100%);
                .outerCircle {
                    background: linear-gradient(to right, rgba(252,205,35,1) 0%,rgba(240,145,21,1) 100%);
                    .innerCircle {
                        background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(204,89,6,1) 100%);
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
            &.disabled {
                opacity: 0.3;
                cursor: default;
            }
            @media (min-width: $break_xs) {
                padding-right: 55px;
            }
        }
        .conditionsList {
            text-align: center;
            .category {
                margin-bottom: 40px;
                .conditions {
                    min-height: 51px;
                    &.addHeight {
                        min-height: 102px;
                    }
                }
            }
            @media (min-width: $break_s) {
                text-align: left;
            }
        }
        .selectedConditions {
            min-height: 194px;
            padding: 20px 0px;
            border-bottom: 1px dashed $textLight;
            .noConditionsSelected{
                text-align: center;
                position: absolute;
                color: #7b868c;
                text-transform: uppercase;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                font-weight: bold;
            }
            .condition {
                background: #665E77;
                .outerCircle {
                    background: linear-gradient(to right, rgba(127,117,148,1) 0%,rgba(83,79,99,1) 100%);
                    .innerCircle {
                        background: linear-gradient(to right, rgba(83,79,99,1) 0%,rgba(62,59,77,1) 100%);
                        img {
                            opacity: 1;
                        }
                    }
                }
                &.selected {
                    background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(252,190,0,1) 100%);
                    .outerCircle {
                        background: linear-gradient(to right, rgba(252,205,35,1) 0%,rgba(240,145,21,1) 100%);
                        .innerCircle {
                            background: linear-gradient(to right, rgba(240,145,21,1) 0%,rgba(204,89,6,1) 100%);
                            img {
                                opacity: 1;
                            }
                        }
                    }
                }
                &.disabled {
                    opacity: 0.3;
                    cursor: default;
                }
            }
            @media (min-width: $break_xs) {
                min-height: 153px;
            }
            @media (min-width: $break_s) {
                min-height: 143px;
            }
            @media (min-width: $break_xs) {
                padding-right: 55px;
            }
        }
    }

    .category {
        margin-bottom: 40px;
        .stageTitle {
            margin-bottom: 30px;
            margin-top: 20px;
            .stage-number {
                display: inline-block;
                height: 30px;
                width: 30px;
                line-height: 35px;
                font-weight: bold;
                font-size: 2rem;
                text-align: center;
                border-radius: 50px;
                color: white;
                background-color: #fcb316;
                font-family: "Tahu", sans-serif;
                position: absolute;
                top: 4.5px;
                left: -35px;
                &.closer {
                    left: -20px;
                }
            }
            h1 {
                color: white;
                font-size: 2.5rem;
                font-family: 'Roboto', sans-serif;
                font-weight: 100;
                margin: 0;
                text-align: left;
            }
            @media (min-width: $break_xs) {
                padding-left: 50px;
                h1 {
                    font-size: 3rem;
                }
                .stage-number {
                    top: 2.5px;
                    left: -5px;
                    width: 40px;
                    height: 40px;
                    line-height: 45px;
                    font-size: 2.5rem;
                    &.closer {
                        left: -5px;
                    }
                }
            }
            @media (min-width: $break_s) {
                padding-left: 60px;
                h1 {
                    font-size: 3rem;
                }
                .stage-number {
                    width: 50px;
                    height: 50px;
                    line-height: 55px;
                    font-size: 3rem;
                }
            }
        }
        .subText {
            color: white;
            text-align: justify;
            padding-bottom: 30px;
        }
    }

    .commitmentLevels {
        .moreInfo {
            text-align: left;
            padding: 20px;
            color: #e4e4e4;
        }
    }
    .commitmentSelector {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 200%;
        .row{
            margin-bottom: 20px;
        }
        .left {
            z-index: 1;
            padding: 0px;
            .inner {
                padding: 15px 20px;
                background-color: white;
                border-radius: 4px;
                .selectorRow {
                    padding: 28px 0px 28px 90px;
                    .image {
                        border-radius: 2px;
                        height: 80px;
                        width: 120px;
                        background-color: lightgrey;
                        background-size: cover;
                        background-position: center;
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .button {
                        width: 100%;
                    }
                    .toggleButton.disabled {
                        opacity: 0.7;
                    }
                }
            }
        }
        .right, .under {
            padding-left: 0px;
            .inner {
                background-color: #625c6d;
                color: white;
                padding: 30px;
                padding-left: 90px;
                border-radius: 0px 10px 10px 0px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                img {
                    position: absolute;
                    top: 50%;
                    width: 100px;
                    left: -30px;
                    transform: translateY(-50%);
                }
                p {
                    font-size: 2.4rem;
                    margin-bottom: 0px;
                    font-weight: 100;
                }
                b {
                    font-size: 1.2rem;
                }
            }
            &.under {
                padding: 0px 20px;
                .inner {
                    padding-left: 30px;
                    padding-top: 50px;
                    position: relative;
                    top: initial;
                    transform: initial;
                    border-radius: 0px 0px 10px 10px;
                    img {
                        top: -15px;
                        left: 30px;
                        width: 60px;
                        -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
    .stageTitle {
        margin-bottom: 30px;
        margin-top: 20px;
        .stage-number {
            display: inline-block;
            height: 30px;
            width: 30px;
            line-height: 35px;
            font-weight: bold;
            font-size: 2rem;
            text-align: center;
            border-radius: 50px;
            color: white;
            background-color: #fcb316;
            font-family: "Tahu", sans-serif;
            position: absolute;
            top: 4.5px;
            left: -35px;
            &.closer {
                left: -20px;
            }
        }
        h1 {
            color: white;
            font-size: 2.5rem;
            font-family: 'Roboto', sans-serif;
            font-weight: 100;
            margin: 0;
            text-align: left;
        }
        @media (min-width: $break_xs) {
            padding-left: 50px;
            h1 {
                font-size: 3rem;
            }
            .stage-number {
                top: 2.5px;
                left: -5px;
                width: 40px;
                height: 40px;
                line-height: 45px;
                font-size: 2.5rem;
                &.closer {
                    left: -5px;
                }
            }
        }
        @media (min-width: $break_s) {
            padding-left: 60px;
            h1 {
                font-size: 3rem;
            }
            .stage-number {
                width: 50px;
                height: 50px;
                line-height: 55px;
                font-size: 3rem;
            }
        }
        .moreInfo {
            text-align: left;
            padding: 20px;
            color: #e4e4e4;
        }
    }
    .registration-section {
        padding-left: 40px;
        margin-bottom: 40px;
        .startDate {
            text-align: center;
            label {
                font-weight: bold;
                width: initial;
                color: white;
                margin: 0px;
                display: inline-block;
                padding-right: 20px;
            }
            .moreInfo {
                text-align: left;
                padding: 20px;
                color: #e4e4e4;
            }

        }
    }
    .container-button{
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
        .error{
            color: red;
            font-size: 1.2rem;
            margin-top: 0.2em;
        }
    }
    .freeTrial {
        padding: 12px 30px;
        border-radius: 100px;
        border: 2px solid grey;
        background-color: transparent;
        color: white;
        font-weight: bold;
        color: #d9d9d9;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
            color: white;
            border-color: white;
        }
        &:focus {
            outline: none;
        }
    }
    .paymentSection {
        min-height: 420px;
        .moreInfo {
            padding: 0px;
        }
        .paymentOption {
            color: white;
            padding: 10px;
            font-weight: 100;
            .paymentOption-heading {
                font-weight: 100;
                font-size: 2rem;
                margin: 0px;
                padding: 0px 10px 20px 0px;
            }
            & > .inner {
                box-shadow: 0px 4px 10px #0000000f;
                padding: 25px;
                border-radius: 10px;
                background-color: #625c6d;
                min-height: 340px;
                text-align: left;

                .paymentField, .cardField {
                    margin-top: 20px;
                }
                .tsAndCs {
                    padding: 12px 0px;
                    text-align: left;
                }
                .checkboxField {
                    padding: 8px;
                    display: inline-block;
                    left: 0px;
                    top: 8px;
                }
            }
            &.free-trial {
                .inner {
                    background-color: #534e5f;
                }
                button {
                    padding: 12px 30px;
                    border-radius: 100px;
                    border: 2px solid grey;
                    background-color: transparent;
                    color: white;
                    font-weight: bold;
                    color: #d9d9d9;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    &:hover {
                        color: white;
                        border-color: white;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
            &.full-plan {
                .moreInfo {
                    padding-right: 80px;
                }
                .price-badge {
                    position: absolute;
                    right: 11px;
                    top: 80px;
                    transition: transform 0.3s ease;
                }
                @media (max-width: 875px) {
                    .price-badge {
                        transform: translateY(56px);
                    }
                }
                @media (max-width: 615px) {
                    .price-badge {
                        transform: translateY(32px) scale(0.7);
                    }
                }
                @media (max-width: 538px) {
                    .price-badge {
                        transform: translateY(-20px) scale(0.6);
                    }
                }
                button {
                    padding: 12px 30px;
                    border-radius: 100px;
                    border: 2px solid grey;
                    background-color: transparent;
                    color: white;
                    font-weight: bold;
                    color: #d9d9d9;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    &:hover {
                        color: white;
                        border-color: white;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

}

}